.search__result {
 	@include create-main-wrapper;
	@include create-row;
    @include create-row-card;
    @include create-row-card-footer;
	&_no-result-found{
    @include create-row;
    @include create-row-card;
    box-shadow: $row-shadow-color;

	}
	&_loader {
		@include create-row;
		@include create-row-card;
		box-shadow: $row-shadow-color;
		padding-top: 15rem;
    	padding-bottom: 15rem;
	}
  	
    &_footer {
      @include clearfix;
      background-color: $brand-color-4;
    }
    &_card {
      box-shadow: $row-shadow-color;
    }
}

.no-result-found{
	width:100%;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
	&__error-msg {
	width: 80%;
    margin: 0 auto;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    line-height: 2.2rem;
    font-size: 1rem;
	}
	
	&__reset-button {
	@include clearfix;
	padding-top: 7px;
    text-align: center;
	    &_reset-all{
	    
	    
	    background-color: $brand-color-3;
	    margin-left: 2px;
	    text-transform: uppercase;
		     > button{
		     background-color: $brand-color-3;
		    text-transform: uppercase;
			float: left;
		    color: $brand-color-2;
		    text-decoration: none;
		    padding: 0.375rem 0.625rem;    
		    
			    &:hover{  
			      background-color: $brand-color-2;
			    color: $light;
			    }
		    }
	    }
    
   
	}

}