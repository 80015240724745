@use "sass:math";

.recent-booking-details {
	&__title {
		color: $brand-color-2;
	    font-size: 1.25rem;
	    text-align: center;
	    border-bottom: .0625rem solid $brand-color-3;
	    padding-bottom: .5rem;
	}
	
	&__alert-message{
		padding-top: 1rem;
	}
	
	&__back-to-transaction-list {
	    cursor: pointer;
	    font-size: 0.75rem;
	    margin-top: 0.25rem;
	    color: $brand-color-2;
	    text-align: right;
	    
	    &_label {
	    	margin-right: 0.5rem;
	    	&:hover {
	    		text-decoration: underline;
	    	}
	    }
    }
	
	&__wrapper {
		&_headers {
			@include clearfix;
		   	padding: math.div($base-padding, 6) math.div($base-padding, 6);
		   	box-shadow: 0 0 0.625rem rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
		    text-transform: capitalize;
			color: $brand-color-2;
			font-size: 0.875rem;
		   	margin: 0.5rem;
			.headers {
				text-transform: uppercase;
				&__booking-reference, &__travel-details, &__request-date, &__travel-date, &__price-details, &__status{
					float: left;
					text-align: right;
					min-height: 1px;
					width:14%; 
					padding-left: 0.3rem;
					padding-right: 0.3rem;
				}
				&__booking-reference, &__travel-details {
					text-align : left;
				}
				&__travel-details {
					width:30%; 
				}
			}	
		}
	}
	&__booking-rows {
		margin: 0.5rem;
    	box-shadow: 0 0 0.375rem rgba(0,0,0,.25);
		&_booking-number {
			text-align : left;
			color: $brand-color-2;
			margin-left: 0.75rem;
			font-family:$font-family-number;
			width: 13%;
		}
		&_update-date {
			text-align : left;
			margin-left: 0.75rem;
			font-family:$font-family-number;
		}
		&_bkg-container {
			&:nth-child(even) {
				background-color: $gray-lighter;
			}
		}
		&_dp-bkg-container {
			margin-left: 1rem;
			&:nth-child(even) {
				background-color: $gray-lighter;
			}
			&:nth-child(odd) {
				background-color: $light;
			}
		}
	}
}