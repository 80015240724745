.insurance-search-form-condition-alert{
	&__content{
		&_header{
			font-size: 14px;
   			font-weight: bold;
    		color: $gray-darker;
		}
		&_title{
			font-size: 14px;
   			font-weight: 700;
    		margin-top: 10px;
		}
		&_info{
			text-align: justify;
    		margin-top: 10px;
    		margin-bottom: 10px;
		}
	}
	&__action-btn{
		text-align: right;
		&_ok-btn{
			width: 30%;
	        text-transform: uppercase;
	        @include make-button($light, $high-light-green, $light, darken($high-light-green, 20));
	        @include button-size($base-padding*0.2, $base-padding*0.2, $font-size-h4, inherit, 0);
		}
	}
}