.transfer-container {
	.product-compare {
		grid-template-columns: repeat(auto-fit, minmax(15rem, 0.9fr));
		&__container {
			&_product-info {
				height: 12.5rem;
				@include clearfix;
				@include compare-description-separator;
				width: 100%;
				padding: $base-padding*0.25 0;
				display: block;
				& after {
					content: "";
				}
			}
		}

		.product-info{
			&__image-container {
				float: left;
				width: 100%;
				display: block;
				&_image {
					max-height: 7rem;
					height: 7rem;
					max-width:100%;
					img {
						max-width: 100%;
						max-height: 100%;
						display: block;
					}
				}
				&_image-name{
					margin-top: 0.25rem;
					text-transform: uppercase;
					float: left;
    				width: 100%;
				}
			}
			&__price {
				float: left;
				width: 100%;
				text-align: right;
				padding-right: 1rem;
				padding-top: $base-padding*0.25;
				&_amount{
				  font-size: $font-size-h3;
				  font-family: $font-family-number;
				  color: $brand-color-1;
				}
			 
			}
		}
	}
}

@media print{
	.modal-open .full-screen-modal{
		width: 100%;
	}
}