.additional-search-params {
	margin-left: 1rem;
	&__hotel-name {
		margin-bottom: 1rem;
		&_heading {
		
		}
		&_value {
			 width: 50%;
		}
	}
	&__star-rating {
		margin-bottom: 1rem;
		&_value {
			margin-left: 1rem;
			margin-bottom: 0.5rem;
			.value{
				&__checkbox{
					margin-right : 0.5rem;
				}
				&__star{
					color: $star-rating;
				}
			}
			
		}
	}
	&__availability-status {
		margin-bottom: 1rem;
		&_heading {
		}	
		&_value {
			margin-left: 1rem;
			margin-bottom: 0.5rem;
			.value{
				&__checkbox{
					margin-right : 0.5rem;
				}
			}
		}
	}
}