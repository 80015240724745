.expand-collapse-btn{
	float: left;
	background-color: $brand-color-2;
	color: $light;
	text-transform: uppercase;
	font-size: $font-size-h6;
	font-weight: 600;
	outline:none;
	width: 130px;
	&__triangle-down{
        @include make-triangle(bottom, 7px, $light);
	 	top:0.5625rem;
	 	position: relative;
	 	margin-left:0.3125rem;
  	}
  	&__triangle-up{
	 	@include make-triangle(top, 7px, $light);
	 	bottom:0.5625rem;
	 	position: relative;
	 	margin-left:0.3125rem;
	}
}