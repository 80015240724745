@use "sass:math";

.cart-display {
    webkit-box-shadow: 0 0 15px rgba(0,0,0,.25);
    box-shadow: 0 0 15px rgba(0,0,0,.25);
    background-color: $light;
    padding: $base-padding*0.2;
	 &__info-message{
		    margin-top: 0.5rem;
	 		position: relative;
	 		border-radius: 4px;
	 		padding: 0.375rem !important;
	 		bottom:0.5rem;
	 		&_icon {
	 			font-size: 1.25rem;
	 			margin-right: 0.25rem;
	 			margin-left: 0.25rem;
	 		}
	 		
	 		&_message {
	 			position: absolute;
	 			top: 0.75rem;
	 		}
    }
    &__title {
      @include clearfix;
      margin-left: 0.375rem;
      margin-right: .375rem;
      padding-bottom: $base-padding*0.2;
      text-transform: uppercase;
      color: $brand-color-2;
      &_heading {
        float: left;
        width: 60%;
        text-align: left;
      }
      &_product-length {
        float: left;
        width: 40%;
        text-align: right;
      }

    }

    &__content {
      &_price-label {
		background-color: $body-bg;
        padding-bottom: .175rem;
        margin-left: -6px;
        margin-right: -6px;
        padding-left: 0.725rem;
        padding-right: 0.725rem;
        padding-top: .375rem;
        color: $brand-color-2;
	    text-transform: uppercase;
	    text-align: center;
      }	
      
      &_price-value {
      	font-size: 1.425rem;
    	color: $brand-color-1;
    	text-align: center;
    	font-family: $font-family-number;
      }
    	
      &_no-product-message {
        padding: $base-padding*0.2;
      }
      
      &_expand {
      	color: $gray;
    	font-size: $font-size-h3;
      }
      
      &_extra-field {
      	padding: 0.375rem;
      }
      
      &_extra-field-header {
        border-bottom: 0.5px solid $gray;
        color: black;
      }
      
      &_extra-amount {
      	float: right;
      	font-family: $font-family-number;
      }
    }

}
.current-product-cart{
    margin-bottom: 1rem;
}

.hotel, .air, .dp, .package, .sightseeing, .hotelair, .transfer, .cars ,.miscellaneous, .insurance, .visa, .marhaba {
  @include create-product-cart-display;
}

.product-price {
  @include create-product-price;
}

.cart-modal{
    max-width: 40%;
    margin-left: 30%;
    margin-top: 10%;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    	&__text{
		    color: $brand-color-2;
		    padding-bottom: math.div($base-padding, 3);
    		margin-left: -20%;
    		margin-right: -20%;
	    }
	    &__button{
    	    width: 30%;
			padding: $base-padding*0.2;
			background-color: $brand-color-3;
	    }
}
.modal-open{
	.cart-modal.modal{
		overflow-x: hidden;
    	overflow-y: hidden;
	}
}
.hotel-room-details{
	padding-left: 0.7rem;
	padding-right: 0.7rem;
}
.hotel-cart__content{
	&_price-row {
		border: 0;
		padding:0;
	}
	&_cancellation {
		float: right;
		a  {
			color: $brand-color-2;
		}
	}
	&_day-wise-rate {
		float: right;
		a  {
			color: $brand-color-2;
		}
	}
}
.hotel-room-title {
	color: $brand-color-2;
}

.hotel-cart-row__value_show-rooms{
    font-size: 1rem;
    color: $brand-color-2;
    &:hover{
    	    cursor: pointer;
    }
    
}
.hotel-cart-row__essential-notes{
	float: right;
	color: $brand-color-2;
}