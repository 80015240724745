.sidebar {

  // @include make-sm-column(3);
  // @include make-md-column(3);
  // @include make-lg-column(3);
  @include make-one-fourth;
}
.content {

  // @include make-sm-column(12);
  // @include make-md-column(9);
  // @include make-lg-column(9);
  @include make-three-fourth;
  	&__error-container{
		float:left;
		width:100%;
		background:$light;
		&_errors{
			color:$brand-color-1;
			padding: 0.3125rem;
            font-size: .9215rem;
            padding-left: 0.9375rem;
		}
	}
  &__header-details-container{
	@include clearfix();
	width:100%;
	&_select-section,&_select-passenger{
		float:left;
    padding-bottom: 1rem;
		> button {
			float:right;
			padding: 0.3125rem 0.925rem;
			color:$brand-color-2;
			background:$light;
			font-size: 1rem;
		}
	}
	&_selected-button{
		>button{
			background: $brand-color-3;
		}
	}
}
}
.main {

  // padding-top: 24px;
}
.main__search {
  .sidebar {

    // padding: 0;
  }
  .content {

    // padding: 0;
  }
}
.row {
  @include clearfix();
}
.collapse {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-property: height,visibility;
  -o-transition-property: height,visibility;
  transition-property: height,visibility;
}
.collapse.in {
  display: block;
}

@media print {
  .sidebar, .content{
    display: none;
  }
} 