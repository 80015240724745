  .pax-type-selector {
    @include clearfix;
    width: 100%;

    &__pax-type-container,
    &__child-age-container {
      float: left;
      width:50%;
    }
    &__child-age-container{
	padding-left: 2rem;
    }
  }