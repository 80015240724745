.detail-date-picker {
	position: relative;

	ul {
		position: absolute;
		z-index: 1000;
		background-color: $brand-color-23;
		padding: $base-padding*0.2;
		border: 1px solid lighten($brand-color-5, 30);

		li,
		div,
		table {
			outline: none;

		}

		td {
			border: 1px solid lighten($brand-color-5, 30);
		}

		button {

			@include button-size(2px, 5px, inherit, inherit, 0);
			@include make-button($dark, $brand-color-23, $brand-color-23, $brand-color-2);

			&:active,
			&.active {
				font-weight: bold;
				background-color: $brand-color-2;
				color: $brand-color-23;
				box-shadow: none;
			}

		}

	}
}

.date-price-info {
	span {
		text-align: center;
		display: block;
		height: 27px;
		text-overflow: ellipsis;

	}

	&__date {
		font-size: 1rem;
	}

	&__details {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: .625rem;
		width: 75px;
		font-family: $font-family-number;
	}
}

.uib-daypicker {
	thead {
		font-size: inherit;

		.text-center {
			text-align: center;
		}
	}
}


.search__date_select ul button[disabled] {
	background-color: white;
	color: $gray-lighting;
}

.search-date_select {
	.theme-datepicker-value:disabled {
		cursor: not-allowed;
	}
}


.search-date-duration {
	@include make-block;
	width: 20%;

	&__field {
		width: 100%;
		padding: 0.5rem 1rem;
		background: none;
		font-size: 0.875rem;
		vertical-align: middle;
		line-height: normal;
		border: 1px solid;
		border-color: $input-border-color;
		max-height: 34px;
	}
}