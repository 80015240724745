.search-form-note{
	  &__advertise {
		  padding: $base-padding;
		  background-color: $advertise-bgcolor;
		  margin-top: 2.375rem;
		  min-height: 23.5rem;
		  p {
		    margin-bottom: 0;
		  }
	}

}
