.marketing-banner-marquee {
  width: 22.5rem;
  overflow: hidden;
  position: relative;
  height: 35rem;
  @include clearfix();
  &__scrolling-elements {
    @include clearfix();
    position: absolute;
    transform: translateY(0%);
    animation: marquee-config 20s linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }
  @keyframes marquee-config {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  &__img {
    width: 22rem;
    height: 12rem;
    padding: 0.3rem;
  }
}
