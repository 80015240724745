.adhoc-markup{
	&__icon{
		width: 2.25rem;
		cursor: pointer;
	}
	&__adhoc-markup-details{
		width: 13rem;
		word-wrap: break-word;
		.adhoc-markup-details{
			&__title{
				font-size: 1rem;
			    color: $brand-color-2;
			    margin-bottom: 0.25rem;
			    border-bottom: 1px solid $brand-color-5;
			}
			&__adhoc-input{
				margin-bottom: 0.25rem;
				&_title{
					margin-bottom: 0.25rem;
					color: $brand-color-2;
				}
				&_text{
					@include hide-number-spinner;
					width: 7rem;
					margin-right: 0.25rem;
					input[type="text"], input[type="number"] {
					    padding: 0.5rem 0.5rem;
					    background: none;
					    vertical-align: middle;
					    line-height: normal;
					    background-color: $input-bgcolor;
					   	border: 1px solid;
					   	border-color: $input-border-color;
					   	max-height: 34px;
					}	
				}
				&_currency{
				
				}
			}
			&__adhoc-action-buttons{
				margin-bottom: 0.25rem;
				&_button {
        			@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
        			width: 49%;
        			height: 1.5rem;
        			font-weight: 700;
        			text-transform: uppercase;
      			}
			}
			&__adhoc-amount,&__selling-amount{
				margin-bottom: 0.25rem;
				&_title{
					color: $brand-color-2;
				}
				&_amount{
					color:$brand-color-1;
				}
				&_currency{
				
				}
			}
		}
	}
}