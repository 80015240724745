@use "sass:math";

@mixin make-cr-button_private($width) {
  @include button-size(math.div($base-padding, 6), 0, 10px, inherit, 0);
  @include make-button($dark, $brand-color-7, $dark, darken($brand-color-7, 20));
  float: right;
  margin-top: ($base-margin*0.5);
  margin-bottom: ($base-margin*0.5);
  margin-right: math.div($base-margin, 6);
  text-align: center;
  text-transform: uppercase;
  width: $width;
}

@mixin  make-cr-button($width) { 
	@include make-cr-button_private($width); 
}