.review__progress {
  @include make-row;
  max-width: 75%;
  counter-reset: step -1;
  margin-bottom: $base-margin*0.5;
  .ancillary{
   @include make-one-sixth;
  }
  .without-ancillary {
  	@include make-one-fifth;
  }
  .without-login {
    @include make-one-fourth;
  }
 .ancillary, .without-ancillary, .without-login {
    text-align: center;
    position: relative;
    text-transform: uppercase;
    font-size: $font-size-h6;
    > span {
      display: inline-block;
      color: $brand-color-2;
      &:before {
        content: counter(step);
        counter-increment: step;
        width: 30px;
        line-height: 30px;
        border-radius: 100%;
        background-color: $light;
        display: block;
        font-size: 1rem;
        margin: 0 auto;
        color: lighten($brand-color-5, 20);
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: lighten($brand-color-5, 20);
        position: absolute;
        left: -50%;
        top: 15px;
        z-index: -1;
      }
      &.active {
        color: $dark;
        &:before {
          background-color: $brand-color-2;
          color: $light;
        }
      }
    }
   .back-to-results-active {
	  color: inherit;
	  cursor: pointer;
	  &:before {
	    content: '<' !important;
	    background-color: lighten($brand-color-5, 20);
	    color: $light;
	  }
	  &:after {
	    content: none;
	    height: 0 !important;
	  }
	  &:hover:before {
	    background-color: $brand-color-1;
	  }
	  &:hover {
	    color: $brand-color-1;
	  }
   }
   .back-to-results-inactive {
	  color: inherit;
	  &:before {
	    content: '<' !important;
	    background-color: lighten($brand-color-5, 20);
	    color: $light;
	  }
	  &:after {
	    content: none;
	    height: 0 !important;
	  }
   }

   .enabled-breadcrumb {
     cursor: pointer;
   }
 }
}
