@use "sass:math";

.multicity-interstitial {
  .modal-content {
    height: 657px;
  }
  .modal-dialog {
    height: 800px;
  }
  .multicity-interstitial__header {
    margin-top: 2%;
  }
  &__footer {
    height: 10%;
  }
  &__progress {
    padding-bottom: 1px;
  }
}


.interstitial {
  .modal-content {
      height: 657px;
  }
  .modal-dialog {
    height: 657px;
  }
  .interstitial__header {
    margin-top: 10%;
  }
  &__footer {
    height: 35%;
  }
  &__progress {
    height: 35%;
    &_details {
      height: 65%;
    }
  }
  .progress {
    margin-top: 1.6rem;
  }
}

.interstitial, .multicity-interstitial {
  .modal-content {
    background-color: $brand-color-4;
    padding: $base-padding*0.5 0;
    width: 100%;
  }
  .modal-dialog {
    width: 60%;
  }
  .interstitial, .multicity-interstitial {
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: 600;
    height: 600px;
    line-height: 18px;
    text-align: center;
    &__progress {
      box-shadow: $row-shadow-color;
      background-color: $light;
      &_name {
        padding-top: 1rem;
        text-align: center;
        color: $brand-color-2;
        text-transform: uppercase;
      }
      &_param {
        width: 100%;
        height: 35%;
        padding-top: $base-padding*0.5;
        padding-bottom: $base-padding*0.5;
      }
      &_details {
        @include clearfix;
        width: 100%;
        padding-top: math.div($base-padding, 3);
        padding-bottom: math.div($base-padding, 3);
      }
      &_active-bar{
        height: 25%;
        }
    }
    &__header {
      height: 30%;
      text-align: center;
      &_tag {
        color: $brand-color-2;
        text-transform: capitalize;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
      }
      &_search-tag {
        color: $brand-color-2;
        padding-top: 1rem;
        margin-bottom: 0;
      }
      &_re-search-tag {
        color: $brand-color-2;
        padding-bottom: 1rem;
        margin-bottom: 0;
      }
    }
    &__footer {
      &_text {
        color: $brand-color-2;
        text-align: center;
        padding-top: 1rem;
      }
    }
  }


  .interstitial-param {
    &__data {
      @include make-one-half;
      text-transform: uppercase;
    }
    &__data:nth-child(1) {
      text-align: left;
    }
    &__data:nth-child(2) {
      text-align: right;
    }
  }
  .interstitial-details {
    padding-top: 2rem;
    &__from,
    &__to {
      @include make-one-fifth;
      padding-top: math.div($base-padding, 3);
      &_name {
        font-size: 1.5rem;
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: 0.2rem;
        color: $brand-color-2;
      }
      &_city,
      &_country-name {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
      &_iata,
      &_city-name {
      	font-size: 0.8rem;
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: 0.2rem;
        color: $brand-color-2;
      }
    }



    &__date {
      @include make-three-fifth;
      font-weight: 600;
      font-family: $font-family-number;
      text-align: center;
      text-transform: uppercase;
      font-size: 1rem;
      padding-top: 0.7rem;
      position: relative;
      &:before,
      &:after {
        width: 30%;
        content: "";
        height: 1px;
        background-color: lighten($brand-color-2, 30);
        position: absolute;
        top: 50%;
      }
      &:before {

        //margin-right: -70px;
        right: 65%;
        top: 1.2rem;
      }
      &:after {

        //margin-right: -70px;
        left: 65%;
        top: 1.2rem;
      }
    }
  }
  .interstitial-oneway__details,.interstitial-pnr__details {
   
    height:45%;
    .interstitial-details{
     &__from {
    	text-align: left;
    }

    &__to {
    	text-align: right;
    }
   }
  }
  .interstitial-return__details {
 
   height:45%;
    .interstitial-details{
     &__from {
    	text-align: right;
    }

    &__to {
    	text-align: left;
    }
   }
  }
  .interstitial-multicity__details {
    padding-bottom: math.div($base-padding, 3);
    height:45%;
    .interstitial-details{
     &__from {
    	text-align: left;
    }

    &__to {
    	text-align: right;
    }
   }
  }
  .interstitial-multicity__details {
    padding-bottom: math.div($base-padding, 3);
    height:45%;
    .interstitial-details{
     &__from {
    	text-align: left;
    }
    
    &__to {
    	text-align: right;
    } 
   }
  }  
  

  .interstitial-dp__details {
    height: 50%;
    padding-top: math.div($base-padding, 3);
  }
  .logo {
    >img {
      padding-bottom: 1rem;
      max-height:3.75rem;
    }
  }
  .progress {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 1.5rem;

    i {
      line-height: 3.5em;
    }
  }
  .progress-bar {
    transition: width 1s ease-in-out;
  }
}

.process-details{
	&__oneway{
		max-height:25%;
	}
	&__return{
		max-height:25%;
	}		
	&__multicity{
		max-height:25%;
	}
}

.user-auth-pax{
	&__interstitial-progress-container{
		box-shadow: $row-shadow-color;
		height:20%;
		background-color: $light;
		&_progress{
		padding-top: 2.2rem;
		}
	}
}	
	
.interstitial-room-info{
	@include make-one-full;
		&__data{
		text-transform : uppercase;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		 &_param{
		 padding-right: 0.1rem;
		 padding-right: 0.1rem;
		 text-transform : uppercase;
		 }
	}
}

.interstitial-pax-info{
	@include make-one-full;
	float:none;
		&__data{
		text-transform : uppercase;
		 padding-right: 0.5rem;
		 padding-left: 0.5rem;
		 text-align : center;
		}
}

.interstitial-login-modal{
	.modal-content {
      	width: 50%;
     	margin-left: 15rem;
  	}
  	.content{
  		width:100%;
  		padding-left: .9375rem;
  	}  	
}

.nearby-airport-modal {
	.modal-content {
    	max-width: 60%;
    	margin-left: 25%;
    	margin-top: 10%;
  	}	
	.modal-open{
   		overflow-y: hidden;
	}
}

