.header__top-bar_user-nav .header-nav {

	&__links {
	    color: $light;
		cursor: pointer;
	    float: right;
		margin-top: 0.25rem;
	    text-transform: uppercase;
	}
	&__booking-params {
		cursor: pointer;
	}
	&__links:hover {
		text-decoration: underline;
	}
}

.header__top-bar{
	background-color: $brand-color-2;
}

.header-nav{
	@include clearfix;
	width: 100%;	
	color: $light;
	padding: 0.1rem 0;
	&__login_data{
		width: 100%;
	}
	&__existing-booking-msg{
	    float: left;
	}
	&__gds-pnr-no{
	   margin-left: 0.313rem;
	}
	&__trans-details{
		display: flex;
		flex-direction: column;
		padding: 0.15rem 0.625rem;
		&_alert-warning{
  		 	width: 55%;
		}
		&_trans-list{
			.trans-list{
				&__row{
					display: flex;
					justify-content: flex-end;
					direction: ltr;
					padding: 0.1rem 0;
					&_links{ 
						line-height : 1.625rem;
						text-transform: uppercase;
						&:hover{ 
							text-decoration : underline;
							cursor: pointer;
						}
					}
					&_booking-param-link{
						padding: 0 0.5rem;
					}
					&_cog{
						font-size: initial;
					}
					&_login-timestamp{
						line-height: 1.625rem;
    					margin-right: 0.825rem;
					}
					&_credit-balance-info{
						display: flex;
						align-items: flex-end;
						padding-left: 0.5rem;
						margin-right: 0.825rem;
						.credit-balance-info {
							&__client-balance {
								margin-right: 0.25rem;
							}
						}
					}
				}
			}
		}
	}
}