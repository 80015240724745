.baggage-selector {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include clearfix;
  // @include create-flight-row;
  padding-top: $base-padding*0.5;
  .content {
    padding-left: $base-padding*0.5;
    padding-right: 0;
    width: 100%;
  }
  &__card {
    box-shadow: $row-shadow-color;
    background-color: $light;
    // padding-left:15px;
    //padding-right:15px;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-left: 0;
    }
    .baggage-heading {
      font-size: $font-size-h3;
      background-color: $brand-color-3;
      &__container {
        padding-top: 5px;
        height: 40px;
        padding-left: 15px;
        width: 22%;
        background-color: $light;
      }
    }
    .header_text {
      height: 30px;
      padding-left: 15px;
      margin-right: $base-margin*0.125;
      font-size: 0.90rem;
      background-color: $gray-lighter;
      margin-top: 5px;
      padding-top: 5px;
      width: 100%;
      &:first-child {
        border-right: 2px solid $dark-gray;
      }
      .inbound-text,
      .outbound-text {
        @include make-one-half;
        padding-left: 0;
        &__text {
          float: left;
          padding-left: 0;
        }
        &__route {
          float: right;
        }
      }
      .inbound-text__one-way {
        @include make-row;
        padding-left: 15px;
        padding-right: 30px;
        &__text {
          float: left;
          padding-left: 0;
        }
        &__route {
          float: right;
        }
      }
    }
    &_container {
      padding-left: 10px;
      margin: 10px 5px;
      @include clearfix;
      width: 100%;
      .baggage-row {
        &:last-child {
          padding-left: 5px;
        }
      }
       .no-baggage-available {
			padding-right: $base-padding*0.5;
       }
      
      .leg-separator{
 		border-right: 2px solid $dark-gray;
	  }
      .baggage-row,
      .baggage-row__one-way {
        @include make-one-half;
        @include clearfix;
        .baggage-selector__header {
          @include clearfix;
          padding-left: 0;
          padding-top: 5px;
          margin: 5px 0;
          //background-color:$gray-lighter;
          color: $brand-color-2;
          height: 25px;
          >ul {
            width: 100%;
            >li {
              @include make-one-fourth;
              padding-left: 0;
              &:first-child {
                width: 40%;
              }
            }
          }
        }
        .baggage-selector__segment {
        	@include clearfix;
            padding-left: 0.25rem;
            padding-top: 0.25rem;
            background-color:$brand-color-22;
            color: $brand-color-2;
            height: 1.6rem;
        }
      }
      .baggage-row,
      .baggage-row__one-way {
        padding-left: 0;
        &__current-trip {
          width: 100%;
          float: right;
          @include clearfix;
          padding: 10px 0;
          border-bottom: 2px solid $gray-lighter;
          .current-pax,
          .current-baggage,
          .baggage-price,
          .clear-baggage {
            @include make-one-fourth;
            padding-left: 0;
          }
          .current-pax {
            width: 40%;
            >input {
              margin-right: 5px;
            }
          }
          .clear-baggage {
            width: 10%;
          }
          .current-baggage,
          .clear-baggage {
            cursor: pointer;
          }
          .current-baggage {
            >select {
              width: 75%;
            }
          }
          .baggage-price {
            padding-left: 25px;
            font-family: $font-family-number;
            &__amount, &__adhoc {
				float: left;
			}
            &__adhoc {
				width: 1rem;
				padding-left: 0.3rem;
			}
            &__amount-with-adhoc {
				color: $brand-color-14;
			}
          }
          .clear-baggage {
            a {
              color: $brand-color-2;
               :hover {
                color: $brand-color-2;
              }
            }
          }
          .current-baggage {
            a {
              color: $brand-color-5;
               :hover {
                color: $brand-color-5;
              }
            }
          }
        }
      }
    }
    &_footer {
      margin-top: -10px;
      .total-fare {
        width: 100%;
        text-transform: uppercase;
        color: $brand-color-2;
        padding-left: 15px;
        height: 30px;
        padding-top: 5px;
        padding-left: 15px;
        font-size: 0.90rem;
        background-color: $brand-color-3;
        .fare-heading {
          @include make-two-fifth;
          padding-left: 0;
        }
        .fare-amount {
          @include make-three-fifth;
          font-family: $font-family-number;
          padding-left: 167px;
        }
      }
    }
    
  }
}



