.pay-later {
 	@include clearfix;
 	width: 100%;
 	padding-bottom: 1rem;
 	padding-top: 1rem;
 	
 	&__action-buttons {
 		margin-top: 1rem;
 		
 		&_hold-bookings {
 			float: right;
		    min-height: 1px;
		    width: 50%;
 			
 			>button {
 	  			@include button-size($base-padding*0.2, $base-padding*0.5, 0.9375rem, $line-height, 0);
 	  			@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
 	  			font-weight: 900;
    			float: right;
			    text-transform: uppercase;
			    width: 50%;
 	  		}
 	  		&:disabled {
 		      background-color: lighten($brand-color-1, 20);
 		      &:hover {
 		        background-color: lighten($brand-color-1, 20);
 		      }
 		      cursor: not-allowed;
 		    }
 		}
 	}

	&__mark-package-container{
		float: left;
	}
 	
 	&__product-container {
 		@include clearfix;
 		width: 100%;
 		padding: 0.625rem 0.625rem 0.625rem 0.625rem;
 		font-size: 1rem;
 		border-bottom: 1px solid $brand-color-3;
 		color: $gray;
 		
 		&_content-tab {
 			@include text-overflow;
 			white-space: normal;
 			float: left;
 			width: 22%;
 			margin-top: 0.375rem;
 			padding-left: 0.25rem;
    		padding-right: 0.5rem;
 		}
 		&_icon {
 			float: left;
 			margin-right: 1.625rem;
 			font-size: 1.25rem;
 		}
 		&_edit-apis{
 			float: left;
 			margin-right: 1.625rem;
 			font-size: 2.25rem;
 			color: $brand-color-2;
 		}
 		&_remove-button {
 			float: right;
 			margin-top: 0.375rem;
 			>button {
 	  			@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
 	  			font-weight: 100;
 	  			border-radius: 4px;
 	  		}
 		}
 		&_date {
 			width: 15%;
 			font-family: $font-family-number;
 			float: left;
 		}
 		&_booking-status {
 			width: 10%;
 			font-family: $font-family-number;
 			float: left;
 		}
 		&_price-details {
 			width: 15%;
   			padding: 0rem;
 			text-align : right;
 			margin-top: 0rem;
 			font-family: $font-family-number;
 			.price-details{
 				&__amount{
					color: $brand-color-1;
					font-size: 1.25rem;
				}
 				&__adhoc-amount{
					color: $brand-color-14;
					font-size: 1.25rem;
				}
				&__currency{
					font-size: .875rem;
	    			margin-top: .3rem;
				}
 			}
 		}
 	}
 	
 	&__on-hold-message-container {
 		margin-top: 0.5rem;
 		position: relative;
 		border-radius: 4px;
 		padding: 0.375rem;
 		
 		&_icon {
 			font-size: 1.25rem;
 			margin-right: 0.25rem;
 			margin-left: 0.25rem;
 		}
 		
 		&_message {
 			position: absolute;
 			top: 0.75rem;
 		}
 	}
 	&__headers {
		@include clearfix;
		&_mop {
			float: left;
			width: 25%;
	   		color: $sf-label-color;
	   		font-size: 0.875rem;
	   		padding-left: 1rem;
		}
		&_loyalty {
			float: left;
			width: 25%;
	   		color: $sf-label-color;
	   		font-size: 0.875rem;
	   		padding-left: 1rem;
		}
	}
	
 	&__values {
		@include clearfix;
		&_mop {
			float: left;
			width: 25%;
			padding: 0.5rem 1rem;
			font-size: 0.875rem;
			line-height: normal;
		}
		&_loyalty {
			float: left;
			width: 25%;
			padding: 0.5rem 1rem;
			font-size: 0.875rem;
			line-height: 1.7rem;
		}
	}
	
	&__endorsement-details{
		width:100%;
		margin-left: 2rem; 
		&_input-header{
			font-size: 0.875rem;
			color: $sf-label-color;
		}
		&_input-container{
			width:25%;
		}
		&_endorsement-details-textbox {
			width:100%;
		}
		&_notification {
			width: 100%;
			color: $brand-color-1;
		}
	}
	
	select, input[type="text"] {
      width: 100%;
      padding: 0.5rem 1rem;
      background: none;
      font-size: 0.875rem;
      vertical-align: middle;
      line-height: normal;
      background-color: $input-bgcolor;
      border: 1px solid;
      border-color: $input-border-color;
      max-height: 34px;
   }

 }