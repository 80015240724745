.itinerary{
   &__product{
	 @include create-main-wrapper;
     @include create-row-card;
     @include create-row-card-header;
     @include create-card-content;
     &_main{
     	.edit-apis-content{
   		    width: 100%;
    		padding-left: .9375rem;
    		padding-right: .9375rem;
     	}
     }
     &_card {
        box-shadow: $row-shadow-color;
        padding-bottom: 0.9375rem;
        &_extra {
            border-bottom: 1px solid #087dc2;
            padding-bottom: 0.9375rem;
        }
     }
     &_footer{
        @include clearfix;
        padding-top: 0.9375rem;
     }
     &_button {
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        background-color: $brand-color-1;
        border: none;
        line-height: inherit;
        border-radius: 0;
        background-image: none;
        width: 25%;
        color: $light;
        font-weight: 700;
        float: right;
        &:hover {
            background-color: darken($brand-color-1, 20);
        }
    }
  }
  &-product-card{
  	&__header{
  		@include clearfix();
  		width:100%;
  		font-size: 0.9rem;
  		text-transform: uppercase;
        color: $brand-color-2;
        padding-left: 0;
        padding-top: 0.9375rem;
        &_expander,
        &_product-name{
        	float: left;
    		padding-right: 0.625rem;
    		padding-top: 0.3125rem;
	        cursor: pointer;
        }
		&_supplier-ref-no{
        	@extend .itinerary-product-card__header_product-name;
        	text-transform: capitalize;
			&__label{
				margin-right: 1rem;
			}	
        }
        &_copy-from{
        	float:right;
        	padding-bottom: 0.3125rem;
        	padding-top: 0.15rem;
        	&-select{
			    padding: 0.3125rem 0.1875rem;
			    background: 0 0;
			    vertical-align: middle;
			    line-height: normal;
			    font-size: .725rem;
			    width:10rem;
			    text-transform: uppercase;
        	}
        	&-btn{
    		    float: left;
			    background-color: $brand-color-2;
			    color: $light;
			    text-transform: uppercase;
			    font-size: .725rem;
			    font-weight: 600;
			    outline: 0;
			    width: 10rem;
			    margin-right: 0px;
			    padding: 0.3125rem 0;
        	}
        }
  	}
  }
}