.seat-selector {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include clearfix;
  // @include create-flight-row;
  padding-top: $base-padding*0.5;
  .content {
    padding-left: $base-padding*0.5;
    padding-right: 0;
    width:100%;
  }
  &__header {
    @include clearfix;
    padding-left: 10px;
    padding-top: 5px;
    margin: 5px;
    //background-color:$gray-lighter;
    color: $brand-color-2;
    height: 25px;
    >ul {
      width: 100%;
      >li {
        @include make-one-fifth;
        padding-left: 0;
      }
    }
  }
  &__card {
    box-shadow: $row-shadow-color;
    background-color: $light;
    // padding-left:15px;
    //padding-right:15px;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-left: 0;
    }
    .seat-heading {
      font-size: $font-size-h3;
      background-color: $brand-color-3;
      &__container {
      padding-top:5px;
        height: 40px;
        padding-left: 15px;
        width: 22%;
        background-color: $light;
      } 
    .seat-img
    {
     height: 1.5625rem;
     width: 1.5625rem;  
    }
    }
    .header_text {
      height: 30px;
      padding-left: 15px;
      margin-right: $base-margin*0.125;
      font-size: 0.90rem;
      background-color: $gray-lighter;
      margin-top: 5px;
      padding-top:5px;
    }
    &_container {
      padding-left: 10px;
      margin: 10px 5px;
      @include clearfix;
      .seat-row {
        width: 100%;
        padding: 10px 0;
        border-bottom: 2px solid $gray-lighter;
        @include make-row;
      }
      .seat-row {
        &__current-leg {
          @include make-one-fifth;
          @include clearfix;
        }
        &__current-leg-seat {
          @include make-four-fifth;
          float: right;
          @include clearfix;
          padding-left: 0;
          .current-pax,
          .seat-price,
          .clear-seat {
            @include make-one-fourth;
          }
          .current-seat{
            @include make-one-fourth;
          }
          .current-seat,
          .clear-seat {
            cursor: pointer;
          }
          .seat-price {
            font-family: $font-family-number;
    		text-align: right;
    		display: table;
    		content: "";
    		&__amount {
				width: 40%;
				float: left;
			}
    		&__adhoc {
				width: 30%;
				float: left;
				text-align: left;
				padding-left: 0.3rem;
			}
    		&__amount-with-adhoc {
				color: $brand-color-14;
			}
          }
          .clear-seat {
            a {
              color: $brand-color-2;
               :hover {
                color: $brand-color-2;
              }
            }
            float : right;
          }
          .current-seat {
           font-family: $font-family-number;
           	&__not-available {
 					color: $brand-color-1;
            	}
            a {
              color: $brand-color-5;
               :hover {
                color: $brand-color-5;
              }
            }
          }
        }
      }

      }
    &_footer {
      margin-top: -10px;
      .total-fare {
        width: 100%;
        text-transform: uppercase;
        color: $brand-color-2;
        padding-left: 15px;
        height: 30px;
        padding-top: 5px;
        padding-left: 15px;
        font-size: 0.90rem;
        background-color: $brand-color-3;
        .fare-heading {
          @include make-two-fifth;
          padding-left: 0;
        }
        .fare-amount {
          @include make-three-fifth;
          font-family: $font-family-number;
          padding-left: 167px;
        }
      }
    }
  }
}
