.country-selector {
	&__wrapper {
	    padding-left: .9375rem;
		padding-right: .9375rem;
		width : 50%;
		float : left;
		&_value:disabled{
  			cursor:not-allowed;
  		}
	}
}