.ancillary-leg-selector{
  background: $brand-color-3;
  &__container{
    float: left;
    color: $ancillary-tab-font-color;
    font-size: $font-size-h5;
    text-transform: uppercase;
    border-right: 1px solid;
    cursor: pointer;
    &_title {
      @include clearfix();
      width: 100%;
      color: $ancillary-tab-font-color;
      position: relative;
      border-width: .656rem;
      font-size: $font-size-base;
      padding: $base-padding*0.5;
    }
    &_title-selected {
      background: $ancillary-tab-selected-bg-color;
      color: $ancillary-tab-selected-font-color;
    }
    &_overview-sector{
      float: left;
      padding-left: 5px;
    }
  }

}