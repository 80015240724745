.holdable-product-container{
	@include clearfix;
 	width: 100%;
 	padding-bottom: 0.5rem;
 	
	&__header{
	    font-size: 0.75rem;
	    padding: 5px 5px 5px 7px;
	    position: relative;
	    cursor: pointer;
	    height: 2.5rem;
	    border-bottom: 2px solid $brand-color-3;
	 	&_heading, &_count{ 
		 	font-size : 0.875rem;;
			padding-left: 0.3125rem;
			padding-top: 0.3125rem;
			color: $brand-color-2;
			float: left;
			margin-top: 0.3125rem;
		}
		&_button{
			border-radius: 100%;
		    width: 1.25rem;
		    height: 1.25rem;
		    position: relative;
		    float: right;
		    margin-top: 0.3125rem;
		    margin-right: 0.3125rem;
		    font-size: 1rem;
		    padding-left: 0.1875rem;
		    padding-top: 0.125rem;
		    color: $brand-color-8;
		}
 	}
 	&__body{
	 	padding: 7px 0 3px 7px;
		&_checkbox{
			float:left;
			margin-top: 1.25rem;
		}
		&_product{
			margin-left: 0.3125rem;
		}
		&_note-message{
			text-transform: none;
            color: $brand-color-1;
            padding-left: 0px;
		}
	}
	.last-child{
		border : none;
	}
}

