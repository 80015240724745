.price-change-info-window {
    padding: 0;
    font-size: $font-size-base;
    @include clearfix;
    font-weight: 600;
    text-align: center;

    &__text {
        font-size: 1rem;
        padding-bottom: 0.625rem;
    }

    &__info-text {
        color: $brand-color-2;
        font-size: $font-size-h3;
    }

    &__cancel-btn, &__adjust-btn {
        width: 25%;
        @include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20));
        @include button-size($base-padding*0.2, $base-padding*0.2, $font-size-h4, inherit, 0);
    }

    &__continue-btn {
        width: 25%;
        @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 20));
        @include button-size($base-padding*0.2, $base-padding*0.2, $font-size-h4, inherit, 0);
    }
}

.modal-open {
    .price-change-info-window.modal {
        overflow-x: hidden;
        overflow-y: hidden;
        max-width: 600px;
        margin: 0 auto;
    }
}