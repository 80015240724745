.pax-wise-adhoc-markup {
    &__title {
        font-size: 1rem;
        color: $brand-color-2;
        margin-bottom: 0.25rem;
        border-bottom: 1px solid $brand-color-5;
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        &_original-fare,
        &_new-fare {
            color: $brand-color-2;
            text-align: center;
        }
    }

    &__adhoc-input {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;

        &_title {
            align-items: baseline;
    		font-weight: 600;
            display: flex;
            .title {
                &__pax-type {
                    color: $brand-color-2;
                    margin-right: 1rem;
                    text-transform: uppercase;
                    width: 2rem;
                }

                &__pax-count,
                &__multiplier {
                    font-family: $font-family-number;
					color: $brand-color-2;
                }
            }

        }

        &_original-fare {
            display: flex;
            justify-content: flex-end;

            .original-fare {
                &__amount {
                    margin-right: 0.5rem;
                    font-family: $font-family-number;
                    color: $brand-color-1;
                    font-size: 0.875rem;
                }
            }
        }

        &_text {
            @include hide-number-spinner;

            input[type="text"],
            input[type="number"] {
                width: 5rem;
                padding: 0.5rem 0.5rem;
                background: none;
                vertical-align: middle;
                line-height: normal;
                background-color: $input-bgcolor;
                border: 1px solid;
                border-color: $input-border-color;
                max-height: 34px;
                font-family: $font-family-number;
                text-align: right;
            }
        }

        &_currency {
            padding: 0.25rem;
        }
    }
    &__message-box{
		margin-bottom: 0.5rem;
	    &_text{
			color: $brand-color-2;
		}
	}

    &__multiplier,
    &__pax-count {
        padding: 0.25rem;
        font-family: $font-family-number;
    }

    &__adhoc-action-buttons {
        margin-bottom: 0.25rem;
		display: flex;
    	column-gap: 0.5rem;
        &_button {
            @include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20));
            width: 49%;
            height: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &__adhoc-amount,
    &__selling-amount {
        margin-bottom: 0.25rem;

        &_title {
            color: $brand-color-2;
        }

        &_amount {
            color: $brand-color-1;
            font-family: $font-family-number;
        }
    }
}