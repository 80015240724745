.footer {
  @include create-main-wrapper;
  background-color: $footer-bgcolor;
  width: 100%;
  color: $footer-color;
  padding-top: $base-padding;
  padding-bottom: $base-padding;
  display : table;
  
  &__section {
    @include make-row;
  }
  &__menu {
    @include make-one-third;
    &_header {
      color: $footer-links-header-color;
    }
    &_links {

    }
    a {
      color: $light;
      &:hover,
      &:focus {
        color: $footer-link-hover-color;
        text-decoration: underline;
      }
    }
  }
}
@media print{
  .footer{
    display: none;
  }
}