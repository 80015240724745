.seat-map-info {
  .seat-map {
    padding-left: 15px;
    margin: 10px 0px;
    border: 2px solid $gray-lighter;
    @include clearfix;
    overflow-x: scroll;
    &__container {
      @include clearfix;
      padding-bottom: 5px;
      width: 90rem;
      .current-column {
        float: left;
        width: 2rem;
        &:first-child {
           width: 3rem;
        }
        &__number,
        &__number-first-child {
          margin-top: 5px;
          float: left;
          width: 100%;
          @include clearfix;
          text-align: center;
        }
        &__number-first-child {
          padding-left: 20px;
        }
        .current-seat__container {
          @include clearfix;
           width: 100%;
           float: left;
          current-seat__other-children {
            float: left;
            width: 100%;
           }
           .current-seat{
            &__first-child,&__other-children {
            .current-seat__seat {
              padding: 5px;
              &_vacant, &_emergency, &_occupied, &_reserved, &_not-available{
                  display: block;
                  width: 18px;
                  height: 18px;
                  background-size: cover;
              }
              >img {
                width: 18px;
              }
            }
          }
          }
         }
          .current-seat__first-child {
            @include clearfix;
            float: left;
            width: 100%;
            .current-seat__number {
              width: 50%;
              float: left;
              padding: 5px 0 5px 7px;
            }
            .current-seat__seat {
              width: 50%;
              float: left;              
              }
          }
          .seat-seperator {
            padding-bottom: 15px;
          }
        }
      }
    }
      .current-seat__seat,.seat-image{
              &_vacant {
              	 background-image: url(../../../stylesheets/modules_new/images/availableSeat.jpg);
              }
              &_emergency{
              	 background-image: url(../../../stylesheets/modules_new/images/emergencySeat.jpg);
              }
              &_occupied{
              	 background-image: url(../../../stylesheets/modules_new/images/occupiedSeat.jpg);
              }
               &_reserved{
              	 background-image: url(../../../stylesheets/modules_new/images/reservedSeat.jpg);
              }
              &_not-available{
              	 background-image: url(../../../stylesheets/modules_new/images/notAvailableSeat.jpg);
              }
     }
    

  .seat-info {
    @include make-row;
    background-color: $gray-lighter;
    padding:5px 0px;
    margin: 10px 0px;
    &__current-seat {
      @include make-one-fifth;
      .seat-image {
      &_vacant, &_occupied, &_reserved, &_not-available, &_emergency{
          display: block;
          width: 18px;
          height: 18px;
          background-size: cover;
          float: left;
          margin-right: 8px;
              }
        >img {
          width: 18px;
        }
      }
      .seat-label {}
    }
  }
}
.seat-map-tooltip{
              width:5%;
              font-family: $font-family-number;
}
            
            
