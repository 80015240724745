@use "sass:math";

.quick-search-box {
   	float: left;
   	
   	&__search-icon {
		position: absolute;
	    right: 0;
	    padding-top: math.div($base-padding, 6);
	    padding-right: math.div($base-padding, 6);
	}
	
   	&__close-icon {
		position: absolute;
	    right: 0;
	    padding-top: math.div($base-padding, 6);
	    padding-right: math.div($base-padding, 6);
	    cursor: pointer;
	}
	
	&__search-text {
		@include clearfix;
	   	width : 100%;	
		position: relative;
		
		>input {
    		float : left;
	 		padding : math.div($base-padding, 7) math.div($base-padding, 6); 
			height: 1.875rem;
			border-radius: 4px;
			border: 1px solid #ccc;
    	}
	}
}