.traveller-age {
    width: 100%;
    display: inline-table;
    margin-bottom: 20px;
	&__wrapper {
	    padding-left: .9375rem;
		padding-right: .9375rem;
		padding-bottom: .9375rem;
		width : 50%;
		float : left;
		.traveller-dob {
			width : 89%;
			float: left;
		}
		.remove-traveller{
		    float:right;
		    color:$brand-color-5;
		    cursor:pointer;
		    width : 10%;
		    font-size: 1rem;
		    margin-top: 2rem;
			padding-left: 1.6375rem;
		}
	}
}
.add-remove-traveller{
	&__wrapper{
	  	height:2rem;
	  	background-color: lighten($brand-color-3,10);
	  	padding:5px 5px 5px 5px;
	    margin-bottom: 10px;
	  	font-size:$font-size-h4;
		.add-traveller{
		  	color:$brand-color-2;
		  	cursor:pointer;
		}
	}
}
