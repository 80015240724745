.recent-booking-details-product-row {
	&__bkg-container {
		@include clearfix;
		width : 100%;
		&_product, &_travel-details, &_request-date, &_travel-date, &_price-details, &_travel-date-not-applicable, &_booking-status{
			float: left;
			word-wrap: break-word;
			text-align: right;
			min-height: 1px;
			width: 14%; 
		}
		&_travel-date-not-applicable {
			padding-right: 2rem;
		}
		&_travel-details {
			width:30%;
			text-align: left;
			padding-top: 0.225rem;
		}
		&_product {
			text-align: left;
			padding-left: 0.75rem;
			padding-top: 0.225rem;
		}
		&_request-date, &_travel-date {
			font-family:$font-family-number;
			padding-top: 0.225rem;
		}
		&_price-details {
			padding-right: 0.45rem;
			.price-details{
				&__multiple-price-label {
					padding-top: 0.225rem;
					color: $brand-color-2;
				}
				&__multiple-price-container {
					&_currency {
						font-size: 0.75rem;
					}
					&_amount {
						color:$brand-color-1;
						font-size: 1rem;
						font-family:$font-family-number;
					}
				}
				
			}
		}
	}
}