.ffn-prefrences__container {
    width: 100%;
    @include clearfix;
    &_heading {
        @include make-two-fifth;
        float: left;
        padding-left: .9375rem;
        padding-right: .9375rem;
        min-height: 1px;
        width: 20%;
        text-align: right;
        padding: 7px 0;
    }
    .ffn-numbers {
        width: 80%;
        float: right;
        &_segment {
            float: left;
            margin-right: -0.5rem;
            padding: 4px 19px 7px 0;
            padding-left: 1rem;
        }
        
        &_segment-iata {
            height: 23px;
            width: 5rem;
            border: 1px solid;
            text-align: center;
            background-color: $gray-lighter;
        }
        &_auto {
            float: left;
            padding: 3px 15px 3px 0;
            padding-left: .5rem;
        }
        &_number {
            float: left;
            padding: 3px 15px 3px 0;
        }
    }
}

.ssr-data {
    @include clearfix;
    width: 100%;
    float: left;
}
.ssr-request__container {
    &_heading {
        @include make-two-fifth;
        width: 20%;
        text-align: right;
    }
    &_content {
        @include make-three-fifth;
        .add-ssr__wrapper {
            .add-ssr {
                padding: 7px 0px;
                cursor: pointer;
                width: 30%;
            }
        }
    }
}
.ssr {
    &__selector {
        @include clearfix;
        &_close {
            @include make-one-third;
            float: right;
            color: $brand-color-5;
            cursor: pointer;
            padding: 5px 0;
            width: 0%;
        }
        &_options,
        &_text {
            @include make-one-half;
            text-transform: uppercase;
            padding: 3px 0;
            padding-right: 15px;
            >select {
                width: 100%;
                height: 23px;
            }
            >input {
                width: 92%;
            }
        }
    }
}