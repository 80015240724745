.passenger-info {
  &__age_wrapper {
    padding-left: 0.4375rem;
    padding-right: 0.4375rem;
    width: 100%;
    &-sightseeing-age-selector {
      float: left;
      min-height: 1px;
      width: 25%;
      padding-left: 0.4375rem;
      padding-right: 0.4375rem;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        margin-top: 10px;
      }
      .sightseeing-age-selector {
      &__age-select{
       padding: 0.5rem 0;
      }
      }
    }
  }
}
