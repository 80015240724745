.full-screen-modal{
  @include clearfix;
	.modal-dialog {
		margin: auto;
		max-width: 100%;
		height: 100%;
		.modal-content {
			  @include clearfix;
			  padding: 0.9375rem;
			  min-height: 100%;
			  width: 100%;
			  padding-bottom: 0;
			  padding-top: 0;
			  display: inherit;
			}
	}	
}

.full-screen-modal-body {
  position: relative;
  @include clearfix;
  padding: $modal-inner-padding;
  padding-right: 0;
  padding-left: 0;

  .modal {
    &__content {
      @include clearfix;
      scroll-behavior: smooth;
      padding-right: 0.975rem;
      padding-top: 3.5rem;
      position:relative;
      max-height: calc(105vh - #{$full-screen-modal-header-height} - #{$highlights-modal-sidebar-height} - #{$full-screen-modal-heading-height} - #{$base-padding});
    }
  }
}

.full-screen-modal {
	&__content {
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-top: $base-padding*0.5;
    padding-bottom: $base-padding*0.5;
    &_loader{
    padding: 15px;
    margin-left: 45%;
    }
  }
  &__header {
   @include clearfix;
    color: $brand-color-2;
    font-size: $font-size-h4;
    width: 100%;
    height: $full-screen-modal-header-height;
    &_client-logo {
    	width: 80%;
    	float: left;
    	margin-top: 0.3125rem;
    	img {
    		height: 100%;
    	}
    }
    &_heading {
      width: 100%;
      float: left;
      color: $light;
      background-color: $top-bar-bgcolor;
      padding: 0.5rem;
      font-weight: 700;
      font-size: 1.125rem;
      text-transform: uppercase;
      height : $full-screen-modal-heading-height;
    }
    &_close {
      width: 20%;
      margin-top: 0.50rem;
      float: right;
      text-align: right;
      cursor: pointer;
      font-size: 1.125rem;
      padding: $base-padding*0.25;
      padding-right: 0px;
      .close {
        &__icon {
          padding-right: 10px;
        }
      }
    }
    &_info {
      margin-top: 7px;
      font-size: $font-size-h3;
      background-color: lighten($brand-color-3,8);
      height: 35px;
      padding: 5px 5px 5px 7px;
    }
  }
}
.full-screen-modal-heading {
		&__main {
 			 @include clearfix;
		}	
}

@media print{
  .modal-open .full-screen-modal{
    position: absolute;
    overflow: visible;
    -webkit-print-color-adjust: exact;
    width: 98%;
  }
  .full-screen-modal__header_close{
    display:none;
  }
  .full-screen-modal__header_heading {
    -webkit-print-color-adjust: exact;
    font-weight: 700;
  }
  .main__result{
    display:none;
  }
 
}


