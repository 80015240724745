@mixin create-cart-tax-display_private {
	&__header {
		@include clearfix;
		cursor: pointer;
		
		&_total-amount-label, &_price-container {
			float: left;
			width: 50%;
		}
		&_total-amount-label{
			font-size: 12px;
			color: $dark-gray;
		}
		
		&_price-container {
			@include clearfix;
			
			.price-container {
				float: right;
			
				&__amount, &__expand-button {
					float: left;
				}
				
				&__currency {
					float: left;
				    margin-right: .25rem;
				    font-size: .625rem;
				    margin-top: 2px;
				}
				
				&__amount {	
					float: left;
				    font-family: $font-family-number;
				    font-size: 12px;
				    color: $dark-gray;
				}
				
				&__expand-button {
					font-size: .825rem;
					margin-left: .25rem;
				}
			}
		}
	}
	
	&__header-dark {
    	color: $gray;
	}
	
	&-content {
		@include clearfix;
		padding: .25rem;
		width: 100%;
		
		&__headers, &__content {
			@include clearfix;
			width: 100%;
			
			&_label {
				float: left;
			}
			&_amount {
				float: right;
				font-family: $font-family-number;
			}
		}
		
		&__headers {
			border-bottom: 0.5px solid $gray-lighting;
			color: $brand-color-2;
		}
	}
}

@mixin  create-cart-tax-display { 
	@include create-cart-tax-display_private; 
}