//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.input-group {
  position: relative; // For dropdowns
  display: table;
  border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table

  // Undo padding and float of grid classes
  &[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0;
  }

  .form-control {
    // Ensure that the input is always above the *appended* addon button for
    // proper border colors.
    position: relative;
    z-index: 2;

    // IE9 fubars the placeholder attribute in text inputs and the arrows on
    // select elements in input groups. To fix it, we float the input. Details:
    // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
    float: left;

    width: 100%;
    margin-bottom: 0;
  }
}

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
}
// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}

// Button input groups
// -------------------------
.input-group-btn {
  position: relative;
  // Jankily prevent input button groups from wrapping with `white-space` and
  // `font-size` in combination with `inline-block` on buttons.
  // font-size: 0;
  white-space: nowrap;

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    position: relative;
    + .btn {
      margin-left: -1px;
    }
    // Bring the "active" button to the front
    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }
  }

  // Negative margin to only have a 1px border between the two
  &:first-child {
    > .btn,
    > .btn-group {
      margin-right: -1px;
    }
  }
  &:last-child {
    > .btn,
    > .btn-group {
      z-index: 2;
      margin-left: -1px;
    }
  }
}

// Temp

.input-group-btn {
  .btn {
    // @include make-button;
    padding: .475rem 1rem;
    background: none;
    border-radius: 0;
    border: 1px solid lighten($brand-color-5, 30);
    color: $brand-color-2;
    line-height: $line-height-base;
    max-height: 34px;
    &:hover {
      color: $light;
      background-color: $brand-color-2;
    }
    // &:focus {
    //   color: $brand-color-2;
    //   background-color: $light;
    // }
  }
}
