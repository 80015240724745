.marketing-banner-container {
  &__close-icon {
    font-size: 1.5rem;
  }
  &__content {
    overflow-y: auto;
    margin-bottom: 0.75rem;
    padding-top: $base-padding*0.5;
    &_title {
      text-transform: uppercase;
      color: $brand-color-2;
      border-bottom: 0.25rem solid $brand-color-4;
      margin-bottom: 0.75rem;
    }
    &_img {
      height: 38rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
