$seat-available: $aqua-blue;
$seat-occupied: $dark-gray;
$seat-reserved: $water-blue;
$not-available: $dark;
$seat-emergency: $dark-red;
$seat-selected: $orange;
$seat-blocked: $royal-red;
$ancillary-header-bg-color: $light;
$ancillary-header-font-color: $brand-color-2;
$ancillary-container-bg-color: $light;
$ancillary-tab-bg-color: $brand-color-3;
$ancillary-tab-font-color: $gray;
$ancillary-tab-selected-bg-color: $gray;
$ancillary-tab-selected-font-color: $light;
$ancillary-label-color: $brand-color-2;
$ancillary-tooltip-font-color: $dark;
$ancillary-summary-header-bg-color: $brand-color-23;
$ancillary-summary-header-font-color: $brand-color-2;
$ancillary-summary-title-font-color: $brand-color-2;
$ancillary-summary-bg-color: $light;
$ancillary-modal-header-bg-color: lighten($brand-color-3,8);
$ancillary-modal-header-font-color: $brand-color-2;
$ancillary-modal-secondary-header-bg-color: $brand-color-3;
$ancillary-modal-secondary-header-font-color: $brand-color-2;
$ancillary-modal-add-button-bg-color: $brand-color-2;
$ancillary-modal-remove-button-bg-color: $brand-color-1;
$ancillary-modal-button-font-color: $brand-color-23;
$ancillary-modal-save-ancillary-button-bg-color: $brand-color-1;
$ancillary-modal-save-ancillary-button-font-color: $brand-color-23;
$ancillary-modal-close-button-color: $dark;

