.login__popover {
	 float: right;
	.popover{   
 		max-width: none;
 		z-index: 900;
 	}
 	.popover.bottom {
    	margin-top: 0px;
	}
	&_user-id{
		float:left;
	}
	&_user-id-arrow{
		@include draw-triangle(bottom, 5px,$light);
		float: right;
    	margin-top: .5rem;
    	margin-left: .15rem;
	}
}
.logged-in__popover{
	a{
		   text-transform: none;
	}
}
.popover{
	.register__form{
		width:25rem;
	}
	.login_forgot_password_popover{
		width:16rem;
	}
	.login-form-popover{
		max-width:16rem;
	}
	.login__account{
		width:10rem;
		@include clearfix;
		a{
			color:$sf-label-color;
			padding:15px 15px;
			text-decoration: none;
			background-color:transparent;
		}
	}
	.login__account_links{
		@include clearfix;
		width:100%;
		&:hover,
		&:focus
		{
			background-color:$gray-lighter;
		}
	}
	.links__icon{
		margin-right:5px;
	}	
}
