.filter-star-rating {
  @include clearfix;
  &__checkbox {
    float: left;
    padding-top: 0.25rem;
  }
  
  &__stars {
    float: left;
    margin-left: 0.625rem;
    @include clearfix;
    &_star {
      float: left;
      color: $star-rating;
    }
    &_name {
    	float: left;
        color: $dark-gray;
    }
  }
}
