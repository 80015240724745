/*rtl:begin:ignore*/
.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  //height: 4px;
  //margin: 30px 0 15px 0;
  margin-top: $base-margin;
  vertical-align: middle;


  span {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
  }

  .rz-base {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .rz-bar-wrapper {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 32px;
    //padding-top: 16px;
    margin-top: -0.875rem;
    box-sizing: border-box;
  }

  .rz-bar {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background: $brand-color-4;
  }

  .rz-bar.rz-selection {
    z-index: 2;
    background: $filter-bar;
  }

  .rz-pointer {
    top: -20px;
    z-index: 3;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    background-color: $brand-color-2;
    border-radius: 1rem;
  }

  .rz-bubble {
    bottom: 1.5rem;
    padding: 1px 3px;
    color: $brand-color-2;
    cursor: default;
    font-family: $font-family-number;
  }

  .rz-bubble.rz-selection {
    top: 16px;
  }

  .rz-bubble.rz-limit {
    color: $brand-color-2;
  }

  .rz-ticks {
    position: absolute;
    top: -3px;
    left: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    padding: 0 11px;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .rz-ticks .tick {
    width: 10px;
    height: 10px;
    text-align: center;
    cursor: pointer;
    background:$brand-color-2;
    border-radius: 50%;
  }

  .rz-ticks .tick.selected {
    background: #0db9f0;
  }

  .rz-ticks .tick .tick-value {
    position: absolute;
    top: -30px;
    transform: translate(-50%, 0);
  }

}

.rzslider[disabled] {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}
/*rtl:end:ignore*/