.nav__nav-tab {
	&:hover{
		cursor: pointer;
	}
}
.header {

  // margin-bottom: $base-margin;
  &__top-bar {
    @include create-main-wrapper;
    background-color: $top-bar-bgcolor;
    min-height: 30px;
    &_wrapper {
      @include clearfix;
    }
    &_user-nav {
      float: right;
      width: 100%;
      a {
        text-transform: uppercase;
        color: $top-bar-link-color;
        text-decoration: none;
        margin: 0;
        padding: 0.3rem 0.3rem 0 0.3rem;
        float: left;
        &:hover,
        &:focus {
          text-decoration: underline;
          color: $top-bar-link-hover-color;
          background-color: $top-bar-link-bgcolor;
        }
      }
    }
    &_language-nav , &_date-format-nav, &_currency-nav {
      float: left;
      a {
        text-transform: uppercase;
        color: $top-bar-link-color;
        text-decoration: none;
        margin: 0;
        padding: 5px;
        float: left;
        &:hover,
        &:focus {
          text-decoration: underline;
          color: $top-bar-link-hover-color;
          background-color: $top-bar-link-bgcolor;
        }
      }
    }
  }
  &__navbar {
    @include create-main-wrapper;
    background-color: $navbar-bgcolor;
    min-height: 40px;
    &_wrapper {
      @include clearfix;
    }
    &_head {
      padding: $base-padding*0.5 $base-padding*0.5 $base-padding*0.5 0;
      float: left;
      .logo {
        display: block;
        img {
          max-height: 3rem;
        }
      }
    }
    .nav__primary,
    .nav__secondary {
      li {
        float: left;
        display: inline;
        >a {
          text-transform: uppercase;
          color: $navbar-link-color;
          padding-top: 30px;
          padding-bottom: 30px;
          padding-left: 10px;
          padding-right: 10px;
          display: block;
          &:hover,
          &:focus {
            text-decoration: none;
            background-color: $navbar-link-bgcolor;
            color: $navbar-link-hover-color;
          }
        }
        &:last-child {
          >a {
            padding-right: 0;
          }
        }
      }
      .active {
        a {
          text-decoration: none;
          background-color: $navbar-link-bgcolor;
          color: $navbar-link-hover-color;
        }
      }
    }
    .nav__primary {
      float: left;
    }
    .nav__secondary {
      float: right;
    }
    .header__navbar_nav {
      float: right;
    }
  }
}
.language-selector,.date-format-selector {
  padding-bottom: 0.5rem;
  display: inline-block;
}
.header__top-bar_currency-nav{
	.country-selector__wrapper{
      	width: auto;
      	padding-left: 0;
     	padding-right: 0;
     	margin-left: 0.19rem;
 	  	margin-top: 0.3rem;
	}
}
.client-logo {
	display: inline-block;
	img {
		 max-height: 3rem;
	}
}

@media print{
  .header__navbar { 
    display : none;
  }    
}
  