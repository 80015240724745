body {
  text-align: left;
  // font-size: 12px;
  // background-color: $body-color;
  font-weight: $font-weight-base;
  
  .wrapper{
  	min-width : $sm-width;
  	
  	&__main{
  		min-height : 100vh;
  	}
  }
}

.header {
  width: 100%;
}
ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}