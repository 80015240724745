.booking-parameters {
	&__form-container{
   		margin-top: 0.525rem;
		@include create-main-wrapper;
		&_important-notice, &_recent-booking-details {
			border-top:0.3125rem solid $brand-color-3;
		}
	}
	
	&__form {
		
		&_fop-message {
			@include clearfix;
			float: left;
			margin-left: 1.625rem;
	   		margin-top: 0.525rem; 
	   		height: 4.375rem;
	   		color:$brand-color-1;
	   		width: 80%;
		}
		
		h2 {
			margin-left: 0.5rem;
		}
		.itemcontainer {
			left: 0;
			right: 0;
		}
		
		.autosuggestion {
			&__airport {
				&_title{
					width: 100%;
				}
			}
		}
		
		.traveler-autosuggest{
			.search-location_autosuggestion .itemcontainer{
			    z-index: 6;
			}
		}
		
      	@include clearfix;
		background-color: $sf-bgcolor;
  		margin: 0 auto;
  		
		&_field {
			@include clearfix;
			float: left;
			padding-left: 1.625rem;
	   		margin-top: 0.525rem; 
	   		height: 70px;
	   		width:33.33%
		}
		.field {
			&__label{	
				width:100%;
				margin-bottom: 0.25rem;
		   		color: $sf-label-color;
		   		font-size: 0.875rem;			
			}
			&__value{	
				width:fit-content;
			}
		}
		
		input[type="text"],
		select {
			width: 250px;
			padding: .5rem 1rem;
			background: 0 0;
			background-color: $input-bgcolor;
			border: 1px solid $input-border-color;
			font-size: .875rem;
			height:34px;
			vertical-align: middle;
			line-height: normal;
		}
		
		&_button button {
			@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
	   		margin-right: 0.125rem;
	   		margin-top: 1.625rem;
			padding: 0.525rem;
			width: 250px; 
		}
		
		&_button-outer {
			float:right;
			margin-bottom:0.525rem;
			padding-left:1.625rem;
			width:33.33%;
		}
		
		&_row {
			width: 100%;
			display: inline-block;
		}
		
		&_travel-request-button {
			@include clearfix;
			float: left;
			margin-left: 19rem;
	   		margin-top: 0.525rem; 
	   		height: 70px;
	   		width: 250px;
		}
  	}
  	
  	&__loader{
  	    margin-top: 6rem;
  	}
}