.image__container {
  background-color: $body-bg;
  margin-bottom: 30px;
  padding: 1.5rem 0;
  .active__image {
    border: 10px solid $light;
    margin: 12px auto;
    max-width: 650px;
    max-height: 350px;
    min-height: 350px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }
  .active__desc {
  	text-align: center;
  }
  &_active {
    position: relative;
  }
  .slideshow-arrow-prev {
    position: absolute;
    top: 50%;
    left: 50px;
    font-size: 30px;
    font-weight: lighter;
    color: $dark;
    cursor: pointer;
  }
  .slideshow-arrow-next {
    position: absolute;
    top: 50%;
    right: 50px;
    font-size: 30px;
    font-weight: lighter;
    color: $dark;
    cursor: pointer;
  }
}
