@use "sass:math";

.view-cart-display{
    &__popover{
	    cursor:pointer;
	    text-transform: uppercase;
	    color: $dark-gray;
	    padding: $base-padding math.div($base-padding, 3);
	    display: block;
	    &:hover,&:focus {
	         text-decoration: none;
	         background-color: $navbar-link-bgcolor;
	         color: $navbar-link-hover-color;
        }
    }
    
    &__button{
        width: 100%;
    padding: 2%;
    margin-top: 4%;
    color: white;
    background-color: red;
    cursor : pointer;
    hover : {
    background-color: #9B0D11;;
		} 
    }
	.popover{
		z-index: 900;
	}
	.popover-content{
	padding: 8px 12px;
	min-width: 250px;
	max-width: 18rem;
	}
	.popover.bottom {
    margin-top: -14px;
	}
	
}
.popover{
	.cart-display {
    	box-shadow: 0 0 0;
	}
}