.dp-change-product {
  .modal__content {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    &_hotel-highlight-loader{
    @include make-loader;
    }
  }
  .modal-body {
    padding-right: 0;
    padding-left: 0;
  }
}
.hotel-highlights {
	@include clearfix;
	height: 100%;
	width: 100%;

	.hotel-details {
		&__content {
			width: 100%;
			padding-left: 15px;

			.hotel-room-row__container {
				margin: 0;
			}

			.inclusion,
			.room-detail__room-type {
				width: 40%;
			}
		}
	}
  .hotel-info {
    &__hotel-image {
      float: left;
      width: 20%;
      .result__thumbnail {
        width: 100%;
        height: 90px;
	    color: $light;
      }
    }
    &__hotel-description {
      float: left;
      width: 80%;
    }
  }
  .description{
  	&__hotel {
    	padding-left: 10px;
    	width: 100%;
	    &_name {
	      color: $brand-color-2;
	      font-weight: 400;
	      text-align: left;
	      font-size: $font-size-h3;
	    }
  	}
  }
  		
  .hotel-details {
    &__content {
      width: 100%;
      padding-left : 0;
      .hotel-room-row {
      	 &__container {
      	 	margin:0;
      	}
      }
      .room-detail {
           &__room-type{
      			width: 40%;
      	   }
      }
    }
  }
  .hotel-info {
    &__hotel-image {
      float: left;
      width: 20%;
      height: 90px;
      background: $gray-light;
      .result{
      	 &__thumbnail {
       		 width: 100%;
      		 color: $light;
      	 }
      }
    }
    &__hotel-description {
      float: left;
      width: 80%;
    }
  }
  .description{
  	&__hotel {
   		padding-left: 10px;
    	width: 100%;
	    &_name {
	      color: $brand-color-2;
	      font-weight: 400;
	      text-align: left;
	      font-size: $font-size-h3;
	    }
	  }
  }
}	

.hotel-room-list {
	height: auto;
}

.hotel-contact {
	.map-view {
		&__wrapper {
		    margin-top: 10px;
		    margin-bottom: 15px;
		    position: relative;
		  	.map{
		  		height: 100%;
		  		&__wrapper {
			   		height: 500px;
			   	 	width: 100%;
			  	}
			}
		}
	}
}


.highlights-modal {
    &__sidebar {
      float: left;
      padding-left: 0;
      padding-right: 4rem;
      color: $light;
      text-transform: uppercase;
	  height: $highlights-modal-sidebar-height;
	  position: fixed;
      z-index:10;
      z-index: 1;
      width:100%;
      &_hotel-name {
	      @include clearfix; 
	      background-color: $brand-color-2;
	      width: 45%;
	      padding-bottom: 0.625rem;
	      display: flex;
	      float: left;
	      font-weight: 600;
	      text-align: left;
	      font-size: $font-size-h2;
	      padding-top:0.3125rem;
	          span {
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    overflow: hidden;
      }
      }
      &_tabs {
	      @include clearfix; 
	      background-color: $brand-color-2;
	      width: 55%;
	      padding-bottom: 0.66rem;
	      display: flex;
	      justify-content: flex-end;
   		  align-items: flex-start;
    	  flex-direction: row;
    	  flex-wrap: nowrap;
	      float: right;
	      padding-top:0.4375rem;
		  >li{
			  width: 18%;
			  >a{
				  color: $light;
			  }
		  }
	      .tabs__action-button {
	        text-align: center;
	        @include make-one-fourth;
	        padding-left: 0.5rem;
    		padding-right: 0.5rem;
    		min-height: 1.85rem;
    		margin: 2px;
    		font-size: 0.85rem;
    		background-color: $light;
		    border-radius: 0.1875rem;
	        color: $brand-color-2;
	      }
	      .active {
			  a{
				  color: $brand-color-1;
			  }
	      }
	    }
    }
    &__hotel-details {
    	--mdScreenSize : #{$sm-screen-size};
    	float: right;
    	width: 100%;
    	.hotel {

    		&-slideshow,
    		&-contact,
    		&-desc,
    		&-amenity,
    		&-amendment-policy,
    		&-rooms {
    			@include box-shadow(0 3px 9px rgba(0, 0, 0, .5));
    			margin-bottom: $base-margin*0.5;
				background-color: $light;
    			&__header {
    				background-image: linear-gradient(75deg, $brand-color-2 20%, $brand-color-4 90%);
    				padding: 0.375rem;
    				color: $light;
    				text-transform: uppercase;
    			}
    		}
    	}

    }
 }
