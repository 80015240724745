.promocode__desc{
	@include clearfix;
	margin-top: -22px;
	margin-left: 15px;
	margin-right: 15px;
	padding: 0.25rem;
    color: $dark-green;
    background-color: $saffron;
    box-shadow: $row-shadow-color;
    margin-bottom: 1rem;		
	
	&_tag{
		transform: rotate(110deg);
	    float: left;
	    margin-left: 0.5rem;
	    margin-right: 0.5rem;
	    .tag__data{
		    font-size: 1rem;
		    margin-top: -25px;
		    margin-left: 8px;
		    display: block;
		    color: $light;		    
	    }		
	}
	&_text{
		font-size: 0.9rem;
	}
}