.info-window-modal {
	padding: 5% 10% 5% 10%;
	font-size: $font-size-base;
	font-weight: 600;
	text-align: center;
	
	&__text{
		color: $brand-color-2;
		font-size: $font-size-h3;
    	padding-bottom: 0.625rem;
	}
	
	&__button{
	    width: 30%;
    	padding: 0.375rem;
    	background-color: $brand-color-3;
	}
}