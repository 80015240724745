.sightseeing-container {
	.product-compare {
		&__container {
			&_product-info {
				height: 7.5rem;
			}
		}

		.product-info {
			&__image-container {
				&_image {
					height: 4rem;
				}
			}

			&__product-name {
				@include compare-product-name;
			}
		}
	}
}