.servert-restart-notification {
	background-color: $brand-color-23;

	&__warning-message,
	&__server-restart-message {
		@include make-responsive-width('max-width', $sm-width, $md-width, $lg-width);
		margin: 0 auto;
		padding: .625rem 0;
		font-size: 1rem;
		text-transform: capitalize;
		color: $brand-color-20;
		background-color: $brand-color-21;
	}
}