.contact-center {
  &__wrapper {
    @include clearfix;
  }
  &__btn {
    @include clearfix;
    &-add-traveler {
      @include make-cr-button(20%);
    }
    &-proceed{
      @include make-cr-button(10%);
    
    }
    &-modify-booking-params{
      @include make-cr-button(30%);
      margin:0;
      padding:0.5rem;
    }
  }
}
