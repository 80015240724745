.search-location_autosuggestion .itemcontainer {
  max-height: 260px;
  overflow-y: scroll;
  z-index: 5;
  position: absolute;
  background-color: $light;
  border-top: 1px solid $brand-color-2;
  border-right: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;
  border-left: 1px solid $gray-lighter;
  margin-top: -1px;
  top: 100%;
  left: 15px;
  right: 15px;
}
.autosuggestion {
  position: relative;
  &__textbox:disabled{
  	cursor:not-allowed;
  }
  &__airport {
  @include clearfix;
  &_title {
 	width:70%;
  	float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-spacing: normal;
    padding-right:0.3125rem;
  }
  
  &_country {
  	width:30%;
	float:left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-spacing: normal;
    padding-left:0.3125rem;
  }
  
  }
  &__item {
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $brand-color-3;
      /* color: $light; */
    }
    &_title {
      color: $brand-color-2;
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      > .autosuggest-code {
        color: $brand-color-5;
      }
      > .autosuggest-country {
        color: $brand-color-5;
      }
    }
    &_subtitle {
      @include clearfix;
      margin-bottom: 0;
      border-bottom: 1px solid $gray-lighter;
      padding-left: 15px;
      padding-right: 15px;
      > .autosuggest-country {
        float: right;
      }
    }
    &:last-child p:last-child {
      border-bottom: none;
    }
  }
}
.selecteditem {
  background-color: $brand-color-3;
}
