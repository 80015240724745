@use "sass:math";

.traveler-creation {

  &__wrapper {
    @include clearfix;
    border: 5px solid $brand-color-3;
   .itemcontainer {
	  	margin-right:-($base-padding*0.5);
	  	margin-left:-($base-padding*0.5);
  }
  .traveler-tabs{
	width:100%;
	float:left;
  	background-color: $brand-color-3;
  }
  .autosuggestion__item_title{
	  margin:0px;
	  padding:math.div($base-padding, 6);
  }
  }
  &__btn {
    &_add,
    &_cancel {
      @include make-cr-button(10%);
    }
  }
  &__add {
    &-traveler,
    &-dependent {
      float: left;
      width: 20%;
      >a {
        background-color: $brand-color-3;
        border-bottom: 0;
        color: $brand-color-2;
        display: block;
        padding: math.div($base-padding, 6) 0;
        text-align: center;
        text-decoration: none;
        width: 100%;
        cursor: pointer;
      }
    }
    &-traveler.active,
    &-dependent.active {
      >a {
        background-color: $light;
        border-bottom: 0;
        color: $brand-color-2;
        padding: math.div($base-padding, 6) 0;
        width: 100%;
        cursor: default;
      }
    }
  }
  &__form {
    float: left;
    width: 100%;
    padding: $base-padding*0.5;
    .traveler-info {
      padding-bottom: math.div($base-padding, 3);
      &__row-fields {
        @include clearfix;
        background-color: $light;
        text-transform: uppercase;
        width: 100%;
        input[type="text"],
        input[type="email"],
        select {
          height: 24px;
          width: 100%;
        }
        .input-group {
          @include clearfix;
          input[type="text"],
          input[type="email"],
          select {
            width: 100%;
            background-color: $light;
            border: 1px solid $input-border;
            position: relative;
            padding: 0.5rem 1rem;
            vertical-align: middle;
            line-height: normal;
          }
          .input-group-btn {
			.btn{
			 padding:math.div($base-padding, 6) math.div($base-padding, 3);
			 max-height:24px;
			}
          }
        }
      }
      &__row {
        @include clearfix;
        background-color: $brand-color-4;

        text-transform: uppercase;
        width: 100%;
        .fa-asterisk {
          color: $brand-color-1;
          font-size: $font-size-base*0.5;
          vertical-align: text-top;
        }
        &_gender,
        &_title {
          float: left;
          width: 14%;
          padding: $base-padding*0.1;
          padding-top: $base-padding*0.2;
          min-height: 1px;
        }
        &_first-name,
        &_last-name,
        &_date-of-birth,
        &_email,
        &_ref-no,
        &_loyalty-no,
        &_designation,
        &_grade,
        &_cost-center,
        &_traveler-name,
        &_relation,
        &_passport-no,
        &_issue-country,
        &_expiry-date,
        &_nationality,
        &_empty,
        &_mobile-number {
          width: 24%;
          float: left;
          padding: $base-padding*0.2;
          min-height: 1px;
        }
        &_email,
        &_ref-no,
        &_loyalty-no,
        &_designation,
        &_grade,
        &_traveler-name,
        &_relation,
        &_cost-center,
        &_mobile-number,
		&_nationality {
          float: right;
        }
      }
    }
  }
  
}
.traveler-form-field-validation-error{
  		width: 100%;
    	color: $brand-color-1;
    	text-transform: none;
    	font-size: $font-size-h5;
}
