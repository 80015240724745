@use "sass:math";

.cancellation-policy {
	&_product {
		&-header {
			border-bottom: 1px solid $gray-lighting;
			color: $brand-color-2;
			padding: math.div($base-padding, 3);
		}
		&-content {
            padding: math.div($base-padding, 3);
		}
	}
}