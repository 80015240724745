.loader {
	display: block;
	padding: 15px;
	margin-left: 45%;
}


.inline-page-loader {
	width: 100%;
	padding: .625rem;
	padding-left: 0;
	@include clearfix();

	&__loader {
		width: fit-content;
		font-size: 1rem;
		clip-path: inset(0 12px 0 0);
		animation: l4 1s steps(4) infinite;
		font-weight: 600;
		font-family: $font-family-base;

		&:before {
			content: "Loading...."
		}
	}

	&__text {
		float: left;
		font-size: 1rem;
	}
}

@keyframes l4 {
	to {
		clip-path: inset(0 -8px 0 0)
	}
}