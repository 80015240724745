.search-date_select {
  position: relative;
  ul {
    position: absolute;
    z-index: 1000;
    background-color: $light;
    // max-width: 315px;
    padding: $base-padding*0.2;
    border: 1px solid lighten($brand-color-5, 30);
    li, div, table {
      outline: none;

    }
    td  {
      border: 1px solid lighten($brand-color-5, 30);
    }
    button {

      @include button-size(6px, 12px, inherit, inherit, 0);
      @include make-button($dark, $light, $light, $brand-color-2);

      &:active,
      &.active {
          font-weight: bold;
        background-color: $brand-color-2;
        color: $light;
        box-shadow: none;
      }

      .text-muted {
        color:$brand-color-5;
      }
    }

  }
}

.search__date_select ul button[disabled] {
  background-color: white;
  color:$gray-lighting;
}

.search-date_select{
	.theme-datepicker-value:disabled{
  		cursor:not-allowed;
  	}
}


.search-date-duration{
	 @include make-block;
	 width : 20%;
	 &__field{
	 	width: 100%;
	    padding: 0.5rem 1rem;
	    background: none;
	    font-size: 0.875rem;
	    vertical-align: middle;
	    line-height: normal;
	    border: 1px solid;
	    border-color: $input-border-color;
	    max-height: 34px;
	 }
}
