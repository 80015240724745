.ancillary-meal-selector{
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-header;
    @include create-card-content;
    @include clearfix;
    padding-top: $base-padding*0.5;
    .content {
      padding-left: 0 !important;
      padding-right: 0;
      width:100%;
    }

    &__card {
      box-shadow: $card-shadow-color;
      background-color: $ancillary-container-bg-color;
      border-top-left-radius: 0.313rem;
      border-top-right-radius: 0.313rem;
      &_header {
        @include clearfix;
        text-transform: uppercase;
        color: $ancillary-header-font-color;
        background: $ancillary-header-bg-color;
        padding-left: 0.313rem;
        height: 50px;
      }
      .ancillary-meal-expander {
        float: left;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        width: 5%;
        padding-top: 0.5rem;
      }
      .ancillary-meal-heading {
        font-size: $font-size-h3;
        float: left;
        padding-top: 0.375rem;
        &__container {
          display: flex;
          align-items: center;
          height: 40px;
          padding-left: 0.938rem;
          width: 22%;
          &_header-text {
            padding-left: 0.313rem;
          }
        } 
      }

      .ancillary-meal-price {
        font-size: $font-size-h3;
        float: right;
        padding-top: 0.75rem;
        padding-right: 0.75rem;

        &__currency-code{
          font-size: 1.25rem;
          float: left;
          margin-right: 0.25rem;
        }
        &__currency-rate{
          float: right;
          font-family: $font-family-number;
          font-size: 1.25rem;
        }
    }

      &_container{
        padding-left: 0.625rem;
        margin: 0.625rem 0.313rem;
        @include clearfix;
        .ancillary-meal-leg-selector {
          @include clearfix;
          background: $ancillary-tab-bg-color;
          color: $ancillary-tab-font-color;
        }

        .card-content{
          padding-bottom: 0.625rem;
        }
      }
    }
  }