.required {
		position:relative;
		&:after{
			content: "\f069";
		    font-family: FontAwesome;
		    position: absolute;
		    top: 0px;
		    font-size: 0.375rem;
		    color: $mandatory-asterisk-color;
		    padding-left: 2px;
		    padding-bottom: 0.625rem;
	
		}
	}
.form-field-validation-error{
	width: 100%;
	margin-bottom: 1rem;
	color:$brand-color-1;
}
.currency{
	color: $brand-color-5;
}
.amount{
	color: $brand-color-1; 
}
