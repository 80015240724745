.info-message-modal {
    padding: 0;
    font-size: $font-size-base;
    @include clearfix;
    font-weight: 600;

    &__text {
	    text-align: left;
        font-size: 1rem;
        padding-bottom: 0.625rem;
    }

    &__action-btn {
	    text-align: center;
	    &_ok-btn {
	        width: 25%;
	        text-transform: uppercase;
	        @include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20));
	        @include button-size($base-padding*0.2, $base-padding*0.2, $font-size-h4, inherit, 0);
	    }
    }
}

.modal-open {
    .info-message-modal {
        overflow-x: hidden;
        overflow-y: hidden;
        max-width: 600px;
        margin: 0 auto;
    }
}