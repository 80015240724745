.third-party-commission {
	border-top: 1px solid $brand-color-33;
	padding-top: 5px;
	margin-top: 10px;
	&__form {
		display: flex;
		gap: 20px;

		&_label {
			width: 100%;
			margin-bottom: 0.25rem;
			color: $sf-label-color;
			font-size: 0.875rem;
		}

		&_input-currency-label {
			white-space: nowrap;
			vertical-align: middle;
			padding: 4px 8px;
			font-size: 14px;
			font-weight: 600;
			line-height: 1;
			text-align: center;
			border: 1px solid $brand-color-33;
			border-right: none;
			float: left;
		}

		&_input-amount-field {
			border: 1px solid $brand-color-33;
			font-size: 14px;
			float: left;
			padding: 2px;
			width: 180px;
			height: 24px;
		}

		&_validation-error {
			color: $royal-red;
		}

		&_selector {
			select {
				border: 1px solid $brand-color-33;
				background-color: white;
				padding-left: 2px;
				width: 200px;
				height: 24px;
				font-size: 14px;
			}
		}

		&_commission {
			input {
				border: 1px solid $brand-color-33;
				font-size: 13px;
				padding-left: 4px;
			}
		}

		&_cursor-disabled {
			cursor: not-allowed;
		}
	}

	label {
		color: $brand-color-2;
	}

	&__link {
		padding-bottom: 10px;
		color: $link-color;
		text-transform: uppercase;
	}
}

.third-party-commission-amount-container{
	@include clearfix();
	&-disabled{
		cursor: not-allowed;
	}
	&__currency-label{
		white-space: nowrap;
			vertical-align: middle;
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 1;
			text-align: center;
			border: 1px solid $brand-color-33;
			border-right: none;
			float: left;
	}
	&__input{
		border: 1px solid $brand-color-33;
			font-size: 0.875rem;
			float: left;
			padding: 2px;
			width: 180px;
			height: 1.5rem;
			input {
				border: 1px solid $brand-color-33;
				font-size: 0.8125rem;
				padding-left: .25rem;
			}
	}
}