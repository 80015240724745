.login-wrapper {
    @include clearfix;
    width: 100%;

    &__close {
        float: right;
        &_icon {
            background: transparent;
            font-size: 1.25rem;
            color: $brand-color-2;
        }
    }

    .login__page_card {
        box-shadow: none;
    }
}