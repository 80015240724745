.ancillary-seat-selector {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-row-card-footer;
  @include clearfix;
  padding-top: $base-padding*0.5;

  &__content {
    padding-right: 0;
    width: 100%;
  }

  &__card {
    box-shadow: $card-shadow-color;
    background-color: $ancillary-container-bg-color;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    &_header {
      text-transform: uppercase;
      color: $ancillary-header-font-color;
      background: $ancillary-header-bg-color;
      padding-left: 0.3125rem;
      width: 100%;
      height: 50px;

      .ancillary-seat-expander {
        float: left;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        width: 5%;
        padding-top: 0.313rem;
      }

      .ancillary-seat-heading {
        font-size: 1.125rem;
        width: 80%;
        float: left;
        padding-top: 0.313rem;

        &__container {
          padding-top: 0.3125rem;
          height: 2.5rem;
          padding-left: 0.9375rem;
          float: left;
        }

        .seat-img {
          height: 1.5625rem;
          width: 1.5625rem;
        }
      }

      .ancillary-seat-price {
        @include clearfix;
        font-size: 1.125rem;
        padding-top: 0.313rem;
        width: 15%;
        content: '';
        display: table;
        padding-top: 0.625rem;
      }
    }

    &_container {
      @include clearfix;
      padding-left: 0.625rem;
      margin: 0.625rem 0.3125rem;
      background-color: $ancillary-container-bg-color;

      .ancillary-seat-leg-selector {
        @include clearfix;
        background: $ancillary-tab-bg-color;
        color: $ancillary-tab-font-color;
        width: 100%;
      }

      .ancillary-seat-pax-selector {
        &__header {
          @include clearfix;
          color: $brand-color-2;
          border-bottom: 0.0625rem solid $light-water-blue;
        }

        &__pax-details {
          @include make-row;
          width: inherit;
          margin: 0.3125rem;
          padding-top: 0.3125rem;

          &_current-leg {
            @include make-one-fifth;
            @include clearfix;
          }

        }

        .current-pax,
        .seat-price,
        .current-seat {
          @include make-one-fourth;
          margin: 0rem 0.3125rem;
          padding-left: 0rem;
          font-size: $font-size-h4;
        }

        .current-seat {
          cursor: pointer;

          &__seat-number,
          &__seat-clear,
          &__seat-change {
            float: left;
            height: 0.9375rem;
            margin-right: 0.3125rem;
          }

          &__seat-number {
            width: 1.563rem;
          }

          &__seat-change {
            color: $water-blue;
          }

          &__seat-clear {
            color: $brand-color-1;
          }

        }

        .seat-price {
          font-family: $font-family-number;
			&_amount{
				float: left;
				margin-right: 0.25rem;
			}
			&_amount-with-adhoc{
				color : $brand-color-14;
			}
			&_adhoc{
				float: left;
			}
        }

        &__airline-details {
          border-bottom: 0.03125rem solid $separator-color;
          padding-top: .5rem;
          padding-bottom: .5rem;
          color: $ancillary-label-color;
          content: '';
          display: table;
          width: 100%;
          font-size: $font-size-base;

          &_airline-code {
            width: 50%;
            text-align: center;

            .airline-code {

              &__title,
              &__separator,
              &__value {
                float: left;
                padding-right: 0.313rem;
              }

              &__title {
                padding-left: 0.625rem;

              }
            }
          }

          &_airline-class {
            float: left;
            width: 50%;
            text-align: right;
            font-family: $font-family-sans-serif;

            .airline-class {

              &__title,
              &__separator,
              &__value {
                float: left;
                padding-right: 0.313rem;
              }

              &__title {
                padding-left: 0.625rem;
              }
            }
          }
        }
        &__radio-button{
          @include make-one-sixth;
          padding-top: 1rem;
          padding-left: 15px;
        }
      }

    }

    .ancillary-seat-map-selector {
      &__seat-details {
        @include clearfix;
        float: left;
        width: 100%;
        padding: 0.9375rem;
        border: 0.0625rem solid;
        margin-bottom: 0.3125rem;

        &_seat-info {
          float: left;
        }

        &_seat-container {
          overflow-x: scroll;
          white-space: nowrap;
          max-width:100%;

        }

      }

      &__seat-info {
        @include clearfix;
        background-color: $gray-lighter;
        padding: 0.625rem 1rem;
        margin: 0.625rem 0rem;
        width: 100%;
        content: '';
        display: table;

        &_current-seat {
          width: 14%;
          padding-left: 0.3125rem;
          padding-right: 0.3125rem;
          margin-top: 10px;
          float: left;

          .seat-available,
          .seat-occupied,
          .seat-reserved,
          .not-available,
          .seat-emergancy,
          .seat-selected,
          .seat-blocked {
            display: block;
            width: 0.625rem;
            height: 0.625rem;
            background-size: cover;
            float: left;
            margin-right: 0.5rem;
            transform: scaleX(-1);
          }
        }
      }

      .seat-label{
        font-size: 0.625rem;
      }

      .seat-row-container {
        width: 1.563rem;
        display: inline-block;
        &__row-number {
          float: left;
          padding: 0.3125rem;
          width: 1.875rem;
          height: 1.875rem;
          font-size: 1rem;

          &-empty {
            margin-top: 1.875rem;
          }
        }

        &__seat-container {
          float: left;
          width: 1.563rem;
          height: 1.563rem;
          display: flex;
          justify-content: center;
          align-items: center;
		  cursor: pointer;
        }
      }

      .seat-default {
        transform: scaleX(-1);
      }

      .seat-available {
        color: $seat-available;
      }

      .seat-occupied {
        color: $seat-occupied;
      }

      .seat-reserved {
        color: $seat-reserved;
      }

      .not-available {
        color: $not-available;
      }

      .seat-emergancy {
        color: $seat-emergency;
      }

      .seat-selected {
        color: $seat-selected;
      }
      .seat-blocked{
        color: $seat-blocked;
      }
    }
  }
}

.ancillary-seat-map-tooltip {
  width: 200px;
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  &__header{
  	color: $ancillary-label-color;
  	font-size: 0.938rem;
  }
  
  &__seat-number-container,
  &__price-container{
  	@include clearfix();
  	width:100%;
  	padding: 0.313rem;
  	&_label{
  		float:left;
  		width:60%;
  	}
    &_seat-number,
    &_price{
    	float:left;
    	color: $ancillary-tooltip-font-color;
    	font-size: 0.75rem;
    }
  }
 &__seat-extra-info{
    	padding: 0.313rem;
 	&_header{
	 	 color: $ancillary-label-color;
	 	 font-size: 0.938rem;
 	}
    &_details{
	 	padding: 0.313rem;
	 	color: $ancillary-tooltip-font-color;
    }
 }
 

}