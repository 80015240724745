.block {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 1px;
}
.one__full {
  width: 100%;
}
.one__half {
  width: 50%;
}
.one__third {
  width: 33.333%;
}
.one__fourth {
  width:  25%;
}
.one__fifth {
  width: 20%;
}
.three__fifth {
  width:  60%;
}

// Extra grid classes
.three__fourth {
  width:  75%;
}

.four__fifth {
  width:80%;
}

.two__fifth {
  width: 40%;
}


.thumbnails {
  display: block;
  text-align: center;

  > img {
    display: block;
    max-width: 20%;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.sightseeing__search_results .result__book{
	float:right;
}