/*  ==========================================================================
    Index
    ========================================================================== */
/*
*    1. Custom Variables (For Sass)
*    2. Bootstrap
*    3. Base, Layout, Components
*      a. Base
*      b. Layout
*      c. Components
*    4. Fixed Components
*      a. Loader
*      b. Navbar
*    5. Search components Files
*      a. Search Navigation
*      b. Search Form
*    6. Search Result Components
*      a. Modify Search
*      b. Search Filter
*      c. Search Result
*    7. Extra Detail Components
*      a. Flight Details
*      b. Hotel Highligts
*      c. Hotel Carousel
*/
/* Import Custom Variables (Only in Sass, not in CSS)*/
// Include Variables & Mixins ----------------------------
@import "components/variables";
@import "components/mixins";

// Include Bootstrap Variables & Mixins ----------------------------
@import "../../../../lib/bootstrap-sass-3.3.5/assets/stylesheets/bootstrap/variables";
@import "../../../../lib/bootstrap-sass-3.3.5/assets/stylesheets/bootstrap/mixins";
@import "../../../../lib/font-awesome/scss/font-awesome";
@import "../../../../lib/material-design/material-icons.css";

// @import "../../../../lib/bootstrap-sass-3.3.5/assets/stylesheets/bootstrap";
 @import "../../../../lib/bootstrap-sass-3.3.5/assets/stylesheets/bootstrap/popovers";
 @import "../../../../lib/bootstrap-sass-3.3.5/assets/stylesheets/bootstrap/tooltip";
 @import "../../../../lib/bootstrap-sass-3.3.5/assets/stylesheets/bootstrap/dropdowns";
 
// Include Normalize & Reboot ----------------------------
@import "components/commons/base/normalize";
@import "components/commons/base/reboot";

// Include Base Styles ----------------------------
@import "components/commons/base/base";
@import "components/commons/base/layout";
@import "components/commons/base/grid";
@import "components/commons/base/type";

// Include Common Template Styles ----------------------------
@import "components/commons/navbar/navbar";
@import "components/commons/footer/footer";
@import "components/commons/server-restart-notification/server-restart-notification";
@import "components/commons/interstitial-page/loader";

// Include Filter Styles ----------------------------
@import "components/commons/filters/search-filter";

// Include Search Result Styles----------------------------
@import "components/commons/search-result/grid-header";
@import "components/commons/search-result/grid-two-pane-header";
@import "components/commons/search-result/grid-load-more-button";
@import "components/commons/search-result/no-result-found";
@import "components/commons/search-result/grid-matrix-view";

// @import "components/commons/search-result/search-results";
// @import "components/commons/search-result/flight-details";
// Include Search Review Styles ----------------------------
@import "components/commons/pax-info/pax-info";
@import "components/commons/login/login-card";
@import "components/commons/login/login-controller-template";
@import "components/commons/login/login-wrapper";
@import "components/commons/review-progress-nav/review-progress-nav";
@import	"components/commons/form/forms";

//Include  review Page Style
@import "components/commons/review-page/book-card";
@import "components/commons/hotel-highlights/hotel-highlights";
@import "components/commons/hotel-highlights/image-slide-show";

//Include  review Page Style
@import "components/commons/cart-display/cart-display";
@import "components/commons/cart-display/price-change-info-window";

//Include  Interstitial Page Style
@import "components/commons/interstitial-page/interstitial-page";

// Include Component Styles ----------------------------
@import "components/commons/components/inputs";
@import "components/commons/components/buttons";
@import "components/commons/components/glyphicons";
@import "components/commons/components/navs";
@import "components/commons/components/autosuggestion";
@import "components/commons/components/date-picker";
@import "components/commons/components/rzslider";
@import "components/commons/components/sorter";
@import "components/commons/components/star-rating";
@import "components/commons/components/screen-reader";
@import "components/commons/components/progress-bars";
@import "components/commons/components/breadcrumb";
@import "components/commons/components/sightseeing-traveller-selector";
@import "components/commons/components/traveller-selector";
@import "components/commons/components/country-selector";
@import "components/commons/components/pax-info";
@import "components/commons/components/special-service-request";
@import "components/commons/components/meal-selector";
@import "components/commons/components/meal-ancillary-modal";
@import "components/commons/components/baggage-selector";
@import "components/commons/components/seat-selector";
@import "components/commons/components/seat-map";
@import "components/commons/components/expand-collapse-button";
@import "components/commons/components/loader-component";
@import "components/commons/components/quick-search-box";
@import "components/commons/components/shared-components/shared-components";
@import "components/commons/components/itinerary-pax-info";
@import "components/commons/components/retrieve-booking";
@import "components/commons/components/insurance-destination";
@import "components/commons/components/traveller-age";
@import "components/commons/components/adhoc-markup";
@import "components/commons/components/pax-wise-adhoc-markup";
@import "components/commons/components/additional-search-parameter";
@import "components/commons/components/filter-star-rating";
@import "components/commons/components/ui-select";
@import "components/commons/components/recent-booking-details";
@import "components/commons/components/recent-booking-details-product-row";
@import "components/commons/components/cookie-consent";
@import "components/payment/pay-later/pay-later";
@import "components/commons/holdable-product/holdable-product";
// Include Contact center styles ----------------------------
@import "components/commons/contact-center/contact-center";
@import "components/commons/contact-center/traveler-list";
@import "components/commons/contact-center/traveler-creation";
@import "components/commons/common";


// Include Terms and conditions styles ----------------------------
@import "components/commons/terms-and-conditions/terms-and-conditions";


// Include View Cart Display styles ----------------------------
@import "components/commons/view-cart-display/view-cart-display";

// Include Booking Parameters styles ----------------------------
@import "components/booking-parameters/booking-parameters";

// Include Card container styles ----------------------------
@import "components/card-container/card-container";

@import "components/commons/components/header-nav";
@import "components/commons/direction/direction";
@import "components/commons/components/see-more-see-less";
@import "components/commons/promo-code/promo-code";
@import "components/user-feedback/user-feedback-form";

// Include common cancellation policy css  ---------------
@import "components/commons/cancellation-policy/cancellation-policy";

@import "components/commons/info-window/info-window";
@import "components/commons/product-compare/product-compare";
@import "components/commons/product-compare/non-air-product-compare";
@import "components/commons/product-compare/sightseeing-product-compare";
@import "components/commons/product-compare/car-transfer-compare";
@import "components/commons/product-compare/insurance-product-compare";
@import "components/commons/components/near-by-airport";
@import "components/marketing-banner/marketing-banner";
@import "components/search-form-note/search-form-note";
@import "components/marketing-banner/marketing-banner-marquee";

@import "components/air/air-ancillary-select/ancillary-meal-select/ancillary-meal-select";
@import "components/air/air-ancillary-select/ancillary-seat-select/ancillary-seat-selector";
@import "components/air/air-ancillary-select/ancillary-leg-selector/ancillary-leg-selector";
@import "components/air/air-ancillary-select/post-ancillary-model/post-ancillary-selector-modal";
@import "components/commons/components/search-form-promotion";
@import "components/commons/info-message-modal/info-message-modal";
@import "components/commons/components/pax-type-selector";
@import "components/commons/components/gender-selector";
@import "components/commons/components/tentative-amount";
@import "components/insurance/search-form/insurance-search-form-condition-alert";
@import "components/commons/third-party-agent-commission/third-party-agent-commission";
