@use "sass:math";

.traveler-list {
  border: 5px solid $brand-color-3;
  .nav {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    background-color: $brand-color-3;
    >li>a {
      padding: math.div($base-padding, 6) ($base-padding*0.5);
    }
    >li,
    >li>a {
      border-bottom: 0;
      border-radius: 0;
      color: $brand-color-2;
      display: block;
      margin: 0;
      position: relative;
    }
    >li>a:hover {
      text-decoration: none;
      background-color: $light;
    }
    &-tabs {
      >li {
        float: left;
        margin-bottom: -1px;
        >a {
          border: 1px solid transparent;
          line-height: 1.42857;
        }
      }
    }
    &-tabs>li.active {
      >a,
      >a:focus,
      >a:hover {
        background-color: $light;
        color: $brand-color-2;
        cursor: default;
        border: 1px solid transparent;
      }
    }
  }
  .dependent-list,
  .traveler-list {
    &__wrapper {
      min-height:16.75rem;
      overflow: auto;
      @include clearfix();
      max-height: 35rem;
	  &_search{
    	float: left;
   		padding-left: 0.325rem;
    	margin-bottom: 0.5rem;
    	margin-top: 0.5rem;
	}
    }
    &__table {
      width: 100%;
      th,
      td {
        padding: math.div($base-padding, 6);
      }
      &_header {
        tr {
          background-color: $brand-color-4;
        }
        th {
          text-transform: uppercase;
        }
      }
      &_content {
        tr {
          border-bottom: 1px solid $brand-color-4;
          a {
            color: $brand-color-5;
          }
          &:hover {
            background-color: $brand-color-4;
          }
        }
      }
    }
  }
  &__back-to-transaction-list {
	    cursor: pointer;
	    float: right;
	    font-size: 0.75rem;
	    margin-top: 0.25rem;
	    color: $brand-color-2;
	    >i {
	    	font-size: 1rem;
	    }
	    >a {
		    color: $brand-color-2;
	    }
	    
	    &_label:hover {
	    	text-decoration: underline;
	    }
  }
}
