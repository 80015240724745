@mixin create-product-price_private {
  &__amount {
    font-size: $font-size-h4;
    color: $brand-color-1;
  }

  &__currency {
    font-size: $font-size-h6;
  }
}

@mixin  create-product-price { 
	@include create-product-price_private; 
}